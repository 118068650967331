import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls"

export const getCompanyRelRequest = async (page: any, userid: any) => {
  let responseData = {};  
  const pagesize:any = 1000;
  await privateFetch
    .get(
      URL.REQUEST_COMPANYREL(
        page, 
        pagesize,
        userid
      )
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};



export const createCompanyRelRequest = async (data:any) => {
  let responseData = {};
  //console.log("createCompanyRelRequest data",data);
  const requestBody = {
    userId: data.userId,
    companyId: data.companyId,
    subcompanyId: data.subcompanyId,
    roleId: data.roleId,
  };

  //console.log("requestBody=",requestBody)
  //return [];
  await privateFetch
    .post(URL.REQUEST_COMPANYREL_CREATE, JSON.stringify(requestBody), {
        headers: {
            ...CONSTANT.HEADER_TYPE,
        },
      })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
        throw new Error(error.response.data.message);
    });
  return responseData;
};


export const updateCompanyRelRequest = async (data:any) => {
  //console.log("updateCompanyRelRequest data",data);
  let responseData = {};  
  const requestBody = {
    userId: data.userId,
    companyId: data.companyId,
    subcompanyId: data.subcompanyId,
    roleId: data.roleId,
  };
  await privateFetch
    .put(URL.REQUEST_COMPANYREL_UPDATE(data.id), JSON.stringify(requestBody),{
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_update_error);
    });
  return responseData;
};

export const deleteCompanyRelRequest = async (id:any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_COMPANYREL_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log("deleteRequest= ", error)
      //throw new Error(error.response.data.message);
      //throw new Error(properties.com_parval_label_role_delete_error);
     
    });
  return responseData;
};
