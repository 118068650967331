import { CONSTANT } from "./Constants";
export const URL = {

    /** HOME PATH */
    LOGIN_TWOFACTORCODE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/login/twofactor",
    LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/autenticate",
    LOGIN_RECOVERY_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/recoverPasswordAdmin",
    LOGIN_CHANGE_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/changePassword",
    LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/activate",
    SESSIONVALIDATE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",
    
    

    // Get Documents

    REQUEST_DOCUMENTS_FORM: CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + "/documents/list",

    /** maintenance urls */
    REQUEST_CERTCATEGORY_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificate-categories/${page}/${size}`,
    REQUEST_CERTCATEGORY: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificate-categories/${id ? id : ''}`,

    REQUEST_CERTIFICATE_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${page}/${size}`,
    REQUEST_CERTIFICATE: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${id ? id : ''}`,

    /** users urls */
    REQUEST_USER_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/{page}/{size}/?page=${page}&size=${size}`,
    REQUEST_USER_LIST_FILTER: (page:string,size:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
    REQUEST_USER_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
    REQUEST_USER: (id:any) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ''}`,
    REQUEST_USER_PASS: CONSTANT.DISPATCHER_USER_PREFIX + `/user/changePassword`,
    REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,
    REQUEST_ACTIVATE_USER: (code:string, username:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/activate?code=${code}&username=${username}`,

    REQUEST_EXTERNALSERVICE_VALIDATEUSER: CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX + `/external-service/validate-user`,
    REQUEST_EXTERNALSERVICE_INTERNALLIST: (company:string, subCompany?:string) => CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX + `/get-file-information?company=${company}${subCompany ? "&subCompany="+subCompany : "" }`,
    REQUEST_EXTERNALSERVICE_DELETE_INTERNALLIST: (id:string) => CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX + `/delete-file/${id}`,


    /**Web user service api */
    REQUEST_WEBSERVICEUSER_ACTIVATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/webservice-user/activate`,
    REQUEST_WEBSERVICEUSER: (id?:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/webservice-user${id !== 0 ? "/"+id : ''}`,

    
  
    /** Company urls */
    REQUEST_COMPANY: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/filter/?page=${page}&size=${size}`,
    REQUEST_COMPANY_ALL: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/filter/?page=${page}&size=${size}`,
    REQUEST_COMPANY_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/create`,
    REQUEST_COMPANY_UPDATE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/${id ? id : ''}`,
    REQUEST_COMPANY_DELETE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/${id ? id : ''}`,
    REQUEST_COMPANY_BYID: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/filter/?page=0&size=10&sort=desc&filter=${id ? id : ''}`,

    /** SUBCompany urls */
    REQUEST_SUBCOMPANY: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/Subcompany/filter/?page=${page}&size=${size}`,
    REQUEST_SUBCOMPANY_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/Subcompany/create`,
    REQUEST_SUBCOMPANY_UPDATE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/Subcompany/${id ? id : ''}`,
    REQUEST_SUBCOMPANY_DELETE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/Subcompany/${id ? id : ''}`,
    REQUEST_SUBCOMPANY_BYID: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/Subcompany/filter/?page=0&size=10&sort=desc&filter=${id ? id : ''}`,
    REQUEST_SUBCOMPANY_BYID2: (page:string,size:string,companyId:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/Subcompany/filter/?page=${page}&size=${size}&companyId=${companyId}`,

    /** Company realations urls */
    REQUEST_COMPANYREL: (page:string,size:string, userid: string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/companyRelation/filter/?page=${page}&size=${size}&userId=${userid}`,
    REQUEST_COMPANYREL_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/companyRelation/create`,
    REQUEST_COMPANYREL_UPDATE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/companyRelation/${id ? id : ''}`,
    REQUEST_COMPANYREL_DELETE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/companyRelation/${id ? id : ''}`,
    
    /** Role urls */
    REQUEST_ROLE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id ? id : ''}`,
    REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/create`,
    REQUEST_ROLE_UPDATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/`,

    /** Rubro urls */

    // perfil-maintenance/dataTablesJuridic/
    /* REQUEST_RUBRO_LIST: (page:number,size:number) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/dataTablesJuridic/?page=${page}&size=${size}`, */
    REQUEST_RUBRO_LIST: (page:number,size:number,filter?:string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/dataTablesJuridic/${filter !== "" ? '{description}?tableName='+filter : '?page='+page+'&size='+size}`,
    REQUEST_RUBRO_WITH_URL: (url:string, size: number, page: number) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/${url}/?page=${page}&size=${size}&type=j`,
    REQUEST_CREATE_RUBRO: (url: string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/${url}/create`,
    REQUEST_UPDATE_RUBRO: (url: string) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/${url}/`,
    REQUEST_DELETE_RUBRO: (url: string, id:number) => CONSTANT.DISPATCHER_PERFIL_MAINTENANCE_PREFIX + `/${url}/${id}`,

    // Search Client external service

    REQUEST_SEARCH_CLIENT : CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX + `/lexis-nexis/search`,
    REQUEST_SEARCH_CLIENT_BLACKLIST_INTERNALLIST : CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX + `/black-list/search`,
    REQUEST_COUNTRIES : CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/country`,

    //https://mapprisk-client-screening-dispatcher.bitcode-enterprise.dev/v1/maintenance/country
    

    // perfil-investment/

    REQUEST_LIST_INVESTMENT: (page:number,size:number) => CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/users?page=${page}&size=${size}`,
    REQUEST_INVESTMENT_PROFILE_WITH_ID: (id:number) => CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/${id}`,
    REQUEST_INVESTMENT_PROFILE_CALCULATE: (id:number) => CONSTANT.DISPATCHER_PERFIL_INVESTMENT_PREFIX + `/profile/calculate/${id}`,
    /** EmailRole urls */
    REQUEST_EMAIL_ROLE: (roleID?:string|null, filter?:string|null, currentPage?:number, size?:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/${roleID ? roleID+'/' : ''}?${filter ? 'filter='+filter+'&' : ''}page=${currentPage}&size=${size}`,
    REQUEST_EMAIL_ROLE_ID: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/findById/${id ? id : ''}`,
    DELETE_REQUEST_EMAIL_ROLE_ID: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/${id ? id : ''}`,
    REQUEST_EMAIL_ROLE_CU: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/`,

    /** DocumentRole urls */
    REQUEST_DOCUMENT_ROLE_LIST: (filter:string|null, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documentsRole/list/?${filter ? 'filter='+filter+'&' : ""}page=${page}&size=${size}`,
    REQUEST_DOCUMENT_ROLE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documentsRole/${id ? id : ''}`,


    /** document */
    REQUEST_DOCUMENT_LIST: (page:number, size:string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/list/?page=${page}&size=${size}`,
    REQUEST_DOCUMENT: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/${id ? id : ""}`,
    REQUEST_DOCUMENT_UPD: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/{id}`,
    REQUEST_DOCUMENT_UPD_INTERNALLIST: (userId: string, companyId: string, subCompanyId: string | null) =>
    CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX + `/uploadFile?userId=${userId}&companyId=${companyId}${subCompanyId !== "" ? "&subCompanyId="+subCompanyId : ""}`,

    //https://mapprisk-client-screening-dispatcher.bitcode-enterprise.dev/v1/external-service/uploadFile?userId=1&companyId=1

    /** authority */
    REQUEST_AUTHORITY_LIST: (filter:string, page:number, size:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/?${filter ? 'filter='+filter+'&' : ""}page=${page}&size=${size}`,    
    REQUEST_AUTHORITY: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${id ? id : ""}`,
    REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST: (roleId:number, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${roleId}/${page}/${size}?filter=${filter}`,

   

    /** authorityByRole */    
    REQUEST_AUTHORITY_BY_ROLE_LIST: (roleId:string, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${roleId}/${page}/${size}${filter && '?filter='+filter}`,
    /* REQUEST_AUTHORITY_BY_ROLE_LIST: (roleId:string, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${roleId}/${page}/${size}${filter && 'filter='+filter}`, */
    
    
    REQUEST_AUTHORITY_BY_ROLE: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${id ? id : ""}`,

    REQUEST_CLIENT_LIST: (endDate:any, page:number, size:string, startDate:any, ) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/{page}/{size}/?endDate=${endDate}&page=${page}&size=${size}&startDate=${startDate}`,
    REQUEST_CLIENT: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/${id ? id : ''}`,
    /* REQUEST_STORES_CLIENT: (id:string, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/stores/${id ? id : ''}/${page}/${size}`,   */
    REQUEST_STORES_CLIENT: (id:string, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/stores/${id ? id : ''}?page=${page}&size=${size}`,  
    
    
    /**Dashboard */
    REQUEST_COMPANY_DASHBOARD: (startDate:any, endDate: any, companyId?: string, subcompanyId?: string, username?: string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/request-month?startDate=${startDate}&endDate=${endDate}${companyId ? "&companyId="+companyId : ""}${subcompanyId ? "&subcompanyId="+subcompanyId : ""}${username ? "&username="+username : ""}`,
    REQUEST_USERS_DASHBOARD: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/users/count`,
    REQUEST_USERS_BY_COMPANY_DASHBOARD: (companyId?:string, subcompanyId?: string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/users/countBycompany?${companyId ? "companyId="+companyId : ""}${subcompanyId ? "subcompanyId=" + subcompanyId : ""}`,
    REQUEST_TOP_COMPANIES: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/top-companies`,
    REQUEST_ALL_COMPANIES_DASHBOARD:  CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/companies-count`,
    REQUEST_COMPANY_COMPARATION_DASHBOARD: (id:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/request-month${id ? "?company="+id : ""}`,
    REQUEST_CONSULTING_BY_COMPANY_DASHBOARD: (companyId?:string, subcompanyId?: string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/request/count${companyId ? "?companyId="+companyId : ""}${subcompanyId ? "?subcompanyId=" + subcompanyId : ""}`,
    REQUEST_TOP_USERS: (companyId?:string, subcompanyId?: string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/top-users${companyId ? "?companyId="+companyId : ""}${subcompanyId ? "?subcompanyId=" + subcompanyId : ""}`,
    REQUEST_NUMBER_INQUIRIES: (companyId?:string, subcompanyId?: string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/remaining-request${companyId ? "?companyId="+companyId : ""}${subcompanyId ? "?subcompanyId=" + subcompanyId : ""}`,

    REQUEST_MORE_INQUIRIES: (adminID:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/notification/more-request?adminId=${adminID}`,

    

    
    
    /*Borrar -*/
    REQUEST_CUSTOMER_STAGE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/customer-stage`,      
    REQUEST_CUSTOMER_PERSON_TYPE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/customer-person-type`,  
    REQUEST_CUSTOMER_REGISTERED: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/registered-customer`,  
    REQUEST_CUSTOMER_COMPARATION: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/clients-comparation`,     
    REQUEST_CUSTOMER_YEAR: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/registered-customer-year`,     
    REQUEST_CUSTOMER_LEADS: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/leads`,
    /* */  
    
    /**Company relation*/
    REQUEST_COMPANY_RELATION: (page:number, size: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/companyRelation/filter/?page=${page}&size=${size}`,     
    REQUEST_COMPANY_MAPPRISK: (filter:number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/filter/?page=0&size=1&companyId=${filter}`,     
    

    

    /**Subcompany relation*/
    REQUEST_SUBCOMPANY_LIST: (page:number, size: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/Subcompany/list/?page=${page}&size=${size}`, 
    REQUEST_SUBCOMPANY_FILTER: (page:number, size: number, companyId:number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/Subcompany/filter/?page=${page}&size=${size}&companyId=${companyId}`, 


    /**Historical */
    REQUEST_HISTORIC_BY_COMPANY: (page:number, size: string) => `/external-service/historic?page=${page}&size=${size}`,

    // Batch

    REQUEST_BATCH_BY_COMPANY: (page:number, size: string) => `/external-service/lexis-nexis/batch?page=${page}&size=${size}`,
    REQUEST_BATCH_DETAILS: (id:string, page: number, size: string) => `/external-service/lexis-nexis/batch/detail?lotId=${id}&page=${page}&size=${size}`,
    REQUEST_BATCH_RESULT: (id:string, page: number, size: string) => `/external-service/lexis-nexis/batch/result?resultId=${id}`,
    REQUEST_BATCH_UPLOAD: (userId:string, companyId: number, subCompanyId: number) => `/external-service/lexis-nexis/batch/upload?userId=${userId}&companyId=${companyId}${subCompanyId !== 0 ? "&subCompanyId="+subCompanyId : ""}`,
    /* REQUEST_BATCH_UPLOAD: (userId:string, companyId: number, subCompanyId: number) => `/external-service/lexis-nexis/batch/upload?userId=${userId}&companyId=${companyId}&subCompanyId=${subCompanyId !== 0 ? subCompanyId : null}`, */

    // Download PDF

    REQUEST_PDF_DOWNLOAD_INDIVIDUAL:(user: string, searchName:string, companyId: string, subCompanyId?: string) => CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX + `/lexis-nexis/individual-pdf/?user=${user}&searchName=${searchName}&companyId=${companyId}${subCompanyId ? "&subCompanyId="+subCompanyId : ""}`,
    REQUEST_PDF_DOWNLOAD_GENERAL:(user: string,  searchName:string, companyId: string, actualPage: number, totalPages: number, subCompanyId?: string, ) => CONSTANT.DISPATCHER_EXTERNALSERVICE_PREFIX + `/lexis-nexis/general-pdf/?user=${user}&searchName=${searchName}&companyId=${companyId}${subCompanyId ? "&subCompanyId="+subCompanyId : ""}&actualPage=${actualPage}&totalPages=${totalPages}`,
    

   
}

