import { URL } from "../../utils/UrlConstants";
import { publicFetch } from "../../utils/CustomFetch";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";

export const SVloginCodeRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    username: data.username,
    password: data.password,
    userPortal: "Administrativo",
  };
  await publicFetch
    .post(URL.LOGIN_TWOFACTORCODE_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.response.data.message);
    });

  return responseData;
};

export const SVloginRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    username: data.username,
    password: data.password,
    code: data.code,
    userPortal: "Administrativo",
  };
  await publicFetch
    .post(URL.LOGIN_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === 401 ||
          (error.response.status === 500 && error.response.data?.message))
      ) {
        throw new Error(error.response.data.message);
      }
      throw new Error(error.response.data.message);
    });

  return responseData;
};

export const SVRecoveryPassRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    username: data.username,
  };

  await publicFetch
    .post(URL.LOGIN_RECOVERY_PASS_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(properties.com_parval_label_recoverypass_general_error);
    });

  return responseData;
};

export const SVChangePassRequest = async (data) => {
  let responseData = {};
  const requestBody = {
    id: data.id,
    password: data.password,
  };
  await publicFetch
    .put(URL.LOGIN_CHANGE_PASS_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(properties.com_parval_label_forgotpass_general_error);
    });

  return responseData;
};

export const SVActiveUserRequest = async (email, code) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["username", email],
    ["code", code],
  ]);
  await publicFetch
    .get(URL.LOGIN_ACTIVE_USER_REQUEST, { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(properties.com_parval_label_activeregister_general_error);
    });

  return responseData;
};

export const SVSessionValidateRequest = async (email, sessionCode) => {
  let responseData = {};
  const requestBody = {
    username: email,
    sessionCode: sessionCode,
  };
  await publicFetch
    .post(URL.SESSIONVALIDATE_REQUEST, JSON.stringify(requestBody), {
      headers: { ...CONSTANT.HEADER_TYPE },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 500)
      ) {
        throw new Error(properties.com_parval_label_login_sessioncode_error);
      }
      throw new Error(properties.com_parval_label_login_error);
    });

  return responseData;
};
