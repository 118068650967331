import { useEffect } from "react";
import { CONSTANT } from "../../utils/Constants";
import Swal from "sweetalert2";
import { properties } from "../../utils/Properties_es";

interface MessageTypes {
  type: number;
  msg: string;
  details?: string;
  callback?: () => void;
}

const MessageManager = ({ type, msg, details, callback }: MessageTypes) => {
  useEffect(() => {
    showMessage();
    // eslint-disable-next-line
  }, []);

  const showMessage = () => {
    Swal.fire({
      title: `<span style="font-weight: 400">${msg}</span>`,
      html: `${details}`,
      icon: type === CONSTANT.ERROR_MSG ? "error" : "success",
      confirmButtonText: properties.com_parval_label_button_ok_title,
      confirmButtonColor: "#43a491",
    }).then(() => callback && callback());
  };

  return <></>;
};

export default MessageManager;
