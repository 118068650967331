import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const Title = styled(DialogTitle)(({ theme }) => ({
  //background: theme.palette.primary.main,
  color: theme.palette.primary.main,
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "1rem",
  borderBottom: `1px solid #dee2e6`,
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
  mt: 4,
}));

interface QueriesType {
  queries?: number;
}

interface Props {
  cancelModal: () => void;
  onSubmit: any;
  limit: number;
  rate: number;
}

const AddMoreQueries = ({ cancelModal, onSubmit, limit, rate
}: Props) => {
  const validation = Yup.object().shape({
    queries: Yup.number()
      .required("Campo es requerido")
      .typeError("Campo debe ser numérico"),
  });

  const defaultValues = {
    queries: 0,
  };

  const options: any = [];
  for (let i = rate; i <= limit; i += rate) {
    options.push({
      value: i,
      label: i,
    });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<QueriesType>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        Solicitar más consultas
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={8} sx={{ pt: 4, pb: 4 }}>
          <Grid item xs={12}>
            <Controller
              name={"queries"}
              control={control}
              render={({ field: { value } }) => (
                <FormControl fullWidth>
                  <InputLabel>Seleccione</InputLabel>
                  <Select
                    variant="standard"
                    value={value}
                    {...register("queries")}
                  >
                    {options.map((op: any, i: number) => (
                      <MenuItem key={i} value={op.value}>
                        {op.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          name="moreInquiries"
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddMoreQueries;
