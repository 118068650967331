import { useState, useEffect, useCallback } from "react";
import FancyPaperValidateClient from "../components/FancyPaperValidateClient";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  Card,
  CardContent,
  TextField,
  Autocomplete,
  Button,
  Dialog,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  styled,
  Divider,
} from "@mui/material";
import { useApp } from "../hooks/useApp";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useDropzone } from "react-dropzone";
import MessageManager from "../components/utils/MessageManager";
import {
  uploadDocumentInternalList,
  getInternalListDocument,
  deleteDocumentInternalList,
} from "../services/externalapi/ExternalApi";
import { getIndexData } from "../utils/LocalStorageManager";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Dropzone = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
  padding: "60px 30px!important",
}));

interface documentNameType {
  id: number;
  file_name: string;
  company_id: number;
  sub_company_id?: string;
}

const UploadBlackListDocument = () => {
  const theme = useTheme();
  const { setErrorMsg, setLoading, setSuccessMsg, authInfo } = useApp();
  const [currFile, setCurrFile] = useState<File[] | null>(null);
  const [documentName, setDocumentName] = useState<documentNameType | null>(
    null
  );

  const [alert, setAlert] = useState({
    open: false,
    type: 1,
    msg: "",
    details: "",
  });
  const [localCompanyData, setLocalCompanyData] = useState<any>(null);

  const maxSize = 100000;
  const allowExt: any = ["xls", "xlsx", "csv"];

  useEffect(() => {
    const getData = async () => {
      const getLocalCompanyData: any = await getIndexData("companyData");
      setLocalCompanyData({
        company: {
          id: getLocalCompanyData?.companyId,
        },
        subCompany: {
          id: getLocalCompanyData?.subcompanyId,
        },
        massiveRequest:
          getLocalCompanyData?.massiveRequest === null
            ? false
            : getLocalCompanyData?.massiveRequest,
      });
      await handleInternalListDocument(
        getLocalCompanyData?.companyId,
        getLocalCompanyData?.subcompanyId
      );
    };
    getData();
  }, []);

  const handleInternalListDocument = async (
    company: string,
    subCompany?: string
  ) => {
    try {
      setLoading && setLoading(true);
      let data: any = await getInternalListDocument(company, subCompany);

      if (data.id) {
        setDocumentName(data);
        setLoading && setLoading(false);
      } else {
        setDocumentName(null);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  useEffect(() => {
    if (currFile) {
      onSubmit(currFile);
    }
  }, [currFile]);

  const resetField = (field: string) => {
    switch (field) {
      case "file":
        setCurrFile(null);
        break;
      default:
        break;
    }
  };

  const handleUpload = async (e: any) => {
    //console.log("documentName: ", documentName);
    try {
      setLoading && setLoading(true);
      documentName !== null &&
        (await deleteDocumentInternalList(documentName.id));
      await handleInternalListDocument(
        localCompanyData?.company?.id,
        localCompanyData?.subCompany?.id
      );
      const file = e[0];
      const response = await uploadDocumentInternalList(
        authInfo.id,
        localCompanyData?.company?.id || 1,
        localCompanyData?.subCompany?.id || "",
        file
      );
      //console.log("response: ", response);
      if (response === 200) {
        await handleInternalListDocument(
          localCompanyData?.company?.id,
          localCompanyData?.subCompany?.id
        );
        setSuccessMsg && setSuccessMsg("Archivo subido con éxito");
        setLoading && setLoading(false);
        resetField("file");
      }

      /*   setErrorMsg &&
          setErrorMsg("Error reemplazando archivo, informar al administrador");
        setLoading && setLoading(false); */
    } catch (error: any) {
      // console.log(error);
      setErrorMsg && setErrorMsg(error);
      setLoading && setLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    await handleUpload(data);
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles[0]?.name) {
      const fileName = acceptedFiles[0]?.name?.split(".")?.pop()?.toUpperCase();

      const isAllowExt = allowExt.some(
        (ext: any) => ext.toUpperCase() === fileName?.toUpperCase()
      );
      if (!isAllowExt) {
        setAlert({
          open: true,
          type: 2,
          msg: "Tipo de archivo no permitido",
          details: `Solo ${allowExt.toString().replace(/[^A-Z,/]+/gi, " ")}`,
        });

        resetField("file");
        return;
      }
      if (maxSize) {
        if (acceptedFiles[0].size > maxSize) {
          setAlert({
            open: true,
            type: 2,
            msg: "Tamaño de archivo no permitido",
            details: `El archivo supera el tamaño máximo permitido de ${
              maxSize / 1000000
            }Mb`,
          });

          resetField("file");
          return;
        }
      } else {
        if (acceptedFiles[0].size > 1e7) {
          /* setAlert({
            open: true,
            type: 2,
            msg: "Tamaño de archivo no permitido",
            details: "El archivo supera el tamaño máximo permitido de 10Mb",
          }); */
          setErrorMsg &&
            setErrorMsg("El archivo supera el tamaño máximo permitido de 10Mb");
          resetField("file");
          return;
        }
      }
      setCurrFile(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });

  return (
    <FancyPaperValidateClient pagetitle="Cargar documento listas negras">
      <Grid container sx={{ mt: 10 }}>
        <Grid item md={12} sm={12} xs={12}>
          {documentName ? (
            <>
              <Typography
                variant="h5"
                color="initial"
                sx={{ fontWeight: "400" }}
              >
                Nombre de documento: {documentName.file_name.trim()}
              </Typography>
              <Typography
                variant="body1"
                color="error"
                sx={{ fontWeight: "400", mb: 8 }}
              >
                Ya existe un documento de lista interna, si agrega otro, el
                documento actual será reemplazado.
              </Typography>
            </>
          ) : (
            <Typography
              variant="h5"
              color="error"
              sx={{ fontWeight: "400", mb: 8 }}
            >
              Aún no hay un documento cargado
            </Typography>
          )}
          <Box display="flex" justifyContent="center" alignItems="center">
            <Card variant="outlined" sx={{ maxWidth: 600, width: "100%" }}>
              <CardContent>
                <Grid container spacing={3} maxWidth="lg" sx={{ pt: 0, pb: 0 }}>
                  <Grid item sm={12} xs={12}>
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={
                        <CloudUploadIcon
                          fontSize="large"
                          sx={{
                            width: "1.5em",
                            height: "1.5em",
                            color: theme.palette.primary.contrastText,
                          }}
                        />
                      }
                      sx={{
                        padding: "0px!important",
                        width: "100%",
                        maxWidth: "600px",
                        "& .MuiButton-startIcon": {
                          padding: theme.spacing(4),
                          borderRadius: "25rem",
                          background: theme.palette.primary.main,
                          position: "absolute",
                          top: "5px",
                          left: "10px",
                        },
                      }}
                    >
                      <Dropzone {...getRootProps()}>
                        <input
                          {...getInputProps()}
                          name="file"
                          accept={`.xls,.xlsx,.csv`}
                        />

                        <Typography
                          variant="body1"
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          {currFile
                            ? currFile[0].name
                            : "Suelta el archivo aquí ..."}
                        </Typography>
                      </Dropzone>
                    </Button>
                  </Grid>
                  {/*  <Grid
                    item
                    sm={12}
                    xs={12}
                    sx={{
                      textAlign: {
                        xs: "center",
                        sm: "right",
                      },
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      disabled={!currFile}

                      sx={{ mt: 4 }}
                    >
                      Subir archivo
                    </Button>
                  </Grid> */}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
      {alert.open && (
        <MessageManager
          type={alert.type}
          msg={alert.msg}
          details={alert.details}
          callback={() => setAlert({ ...alert, open: false })}
        />
      )}
    </FancyPaperValidateClient>
  );
};

export default UploadBlackListDocument;
