import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls"

export const getRequestSubcomapny = async (page: any, companyId: any) => {
  let responseData = {};    
  const pagesize:any = 1000;
  await privateFetch
    .get(
      URL.REQUEST_SUBCOMPANY_BYID2(
        page, 
        pagesize,
        companyId
      ),  
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequestSubcomapny = async (id:any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_COMPANY_MAPPRISK(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createRequestSubcomapny = async (data:any) => {
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_SUBCOMPANY_CREATE, JSON.stringify(data.dataSubCompanies), {
        headers: {
            ...CONSTANT.HEADER_TYPE,
        },
      })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};


export const updateRequestSubcomapny = async (data:any) => {
  let responseData = {};   
 
  await privateFetch
    .put(URL.REQUEST_SUBCOMPANY_UPDATE(data.id), JSON.stringify(data),{
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_update_error);
    });
  return responseData;
};

export const deleteRequestSubcomapny = async (id:any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_SUBCOMPANY_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
     
    });
  return responseData;
};
