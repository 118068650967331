import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls"

export const RequestCompanyRelation = async (page:number, size:number, filter: any) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["userId", filter.userId ?? ""],
    ["companyId", filter.companyId ?? ""],
    ["subcompanyId", filter.subcompanyId ?? ""],
    ["roleId", filter.roleId ?? ""],
  ]);

  await privateFetch
    .get(
      URL.REQUEST_COMPANY_RELATION(page, size),      
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const RequestSubCompanyFilter = async (page:number, size:number, companyId: number) => {
  let responseData = {};  
  await privateFetch
    .get(
      URL.REQUEST_SUBCOMPANY_FILTER(page, size, companyId),
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};
