import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router";
import {
  Grid,
  Box,
  Typography,
  Divider,
  useTheme,
  Dialog,
} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonAddDisabledIcon from "@mui/icons-material/PersonAddDisabled";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import QueueIcon from "@mui/icons-material/Queue";
import PolicyIcon from "@mui/icons-material/Policy";

import CardDashboard from "../components/CardDashboard";
import PieChartDashboard from "../components/PieChartDashboard";
import ComparativeGraph from "../components/ComparativeGraph";

import { useApp } from "../hooks/useApp";
import { useForm } from "react-hook-form";
import FancyPaper from "../components/FancyPaper";
import {
  getRequestCompaniesMonth,
  getRequestAllUserAdm,
  getRequestAllUserConsulting,
  getRequestTopCompanies,
  getRequestAllCompanies,
  getRequestComparationByMonthCompanies,
  getRequestAllConsultingByCompany,
  getRequestTopUsers,
  getRequestInquiries,
  requestMoreInquiries,
} from "../services/DashboardServices";
import { getIndexData } from "../utils/LocalStorageManager";
import { formatDate } from "../utils/UtilsFunctions";
import AddMoreQueries from "../components/utils/AddMoreQueries";
import { Chart } from "react-google-charts";
import { MoreInquiries } from "../types/Utils";

interface searchData {
  startDate: Date | null;
  endDate: Date | null;
  companyId?: string;
  subcompanyId?: string;
}

const Dashboard = () => {
  const theme = useTheme();
  const {
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    authInfo,
    modalData,
    setModalData,
  } = useApp();
  const [companyStage, setCompanyStage] = useState<any>(null);
  const [subCompanyStage, setSubCompanyStage] = useState<any>(null);
  const [queriesByUserMonthStage, setQueriesByUserMonthStage] =
    useState<any>(null);
  const [topCompanies, setTopCompanies] = useState<any>(null);
  const [allCompanies, setAllCompanies] = useState<any>(null);
  const [comparationByMonthCompanies, setComparationByMonthCompanies] =
    useState<any>(null);
  const [usersByCompany, setUsersByCompany] = useState<any>(null);
  const [consultingByCompany, setConsultingByCompany] = useState<any>(null);
  const [topUsers, setTopUsers] = useState<any>(null);
  const [numberInquiries, setNumberInquiries] = useState<any>(null);
  const [localCompanyData, setLocalCompanyData] = useState<null | any>(null);
  const { handleSubmit } = useForm();

  const date = {
    startDate: formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    ),
    endDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      0
    ).toLocaleString("es-RD", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
  };

  const adminView = authInfo.userRole.authorityRoles.some(
    (authority: any) => authority.authority.code === "ADMIN:VIEWDASHBOARD"
  );

  const userView = authInfo.userRole.authorityRoles.some(
    (authority: any) => authority.authority.code === "USER:VIEWDASHBOARD"
  );

  useEffect(() => {
    const getLocalData = async () => {
      let getLocalCompanyData: any = null;
      getLocalCompanyData = await getIndexData("companyData");
      setLocalCompanyData({
        companyId: getLocalCompanyData?.companyId ?? "",
        companyName: getLocalCompanyData?.companyName ?? "",
        subCompanyID: getLocalCompanyData?.subcompanyId ?? "",
        subCompanyName: getLocalCompanyData?.subCompanyName ?? "",
      });
    };
    getLocalData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const getCompanyMonth = await handleFetchCompaniesStage(
        date,
        localCompanyData?.companyId,
        "",
        ""
      );
      if (getCompanyMonth) {
        setCompanyStage(getCompanyMonth);
      }

      const getSubCompanyMonth = await handleFetchCompaniesStage(
        date,
        localCompanyData?.companyId,
        localCompanyData?.subCompanyID,
        ""
      );
      if (getSubCompanyMonth) {
        setSubCompanyStage(getSubCompanyMonth);
      }

      //if (Number(authInfo.userRole.id) === 2) {
      if (adminView) {
        const getQueriesByUserMonth = await getRequestAllUserAdm();
        if (getQueriesByUserMonth) {
          setQueriesByUserMonthStage(getQueriesByUserMonth);
        }

        /**Todas las compañias admin */
        const getQueriesAllCompanies = await getRequestAllCompanies();
        if (getQueriesAllCompanies) {
          setAllCompanies(getQueriesAllCompanies);
        }
        /** Fin todas las compañias */
      } else {
        await handleAllUserConsultingByCompany();
        await handleNumbersInquiries();
      }
      /**Gráfico de torta */
      await handleFetchTopCompanies();
      /**Fin gráfico de tortas */
      //Gráfico de barras
      await handleFecthComparationCompanies();

      //Todas las consultas primer card
      await handleFectAllConsulting();
    };
    if (localCompanyData !== null) {
      getData();
    }
  }, [localCompanyData]);

  const handleMoreQueries = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: "",
        modalObject: null,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleFetchCompaniesStage = async (
    date: any,
    companyId?: string,
    subcompanyId?: string,
    username?: string
  ) => {
    setLoading && setLoading(true);
    try {
      let dataCompaniesStage = await getRequestCompaniesMonth(
        date,
        companyId,
        subcompanyId,
        username
      );
      if (dataCompaniesStage) {
        setLoading && setLoading(false);
        return dataCompaniesStage;
      }
    } catch (error: any) {
      setLoading && setLoading(false);
      console.log(error);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFecthComparationCompanies = async () => {
    let getComparationByMonthCompanies: any = null;
    //if (Number(authInfo.userRole.id) === 2) {
    if (adminView) {
      getComparationByMonthCompanies =
        await getRequestComparationByMonthCompanies("");
    } else {
      localCompanyData?.subCompanyID !== ""
        ? (getComparationByMonthCompanies =
            await getRequestComparationByMonthCompanies(
              localCompanyData.subCompanyID
            ))
        : (getComparationByMonthCompanies =
            await getRequestComparationByMonthCompanies(
              localCompanyData.companyId
            ));
    }

    if (getComparationByMonthCompanies !== null) {
      setComparationByMonthCompanies(getComparationByMonthCompanies);
    }
  };

  const handleFetchTopCompanies = async () => {
    //if (Number(authInfo.userRole.id) === 2) {
    if (adminView) {
      const getTopCompanies: any = await getRequestTopCompanies();
      if (getTopCompanies) {
        /* getTopCompanies.shift();
        const sortedCompanies = getTopCompanies.map((company: any) => [
          company[1],
          Number(company[0]),
        ]);
        const newData = [["Name", "Count"], ...sortedCompanies];
        setTopCompanies(newData); */
        setTopCompanies(getTopCompanies);
      }
    } else {
      let getTopUser: any = null;
      localCompanyData?.subCompanyID !== ""
        ? (getTopUser = await getRequestTopUsers(
            "",
            localCompanyData.subCompanyID
          ))
        : (getTopUser = await getRequestTopUsers(
            localCompanyData.companyId,
            ""
          ));
      if (getTopUser) {
        setTopUsers(getTopUser);
      }
    }
  };

  const handleFectAllConsulting = async () => {
    let getConsultingByCompany: any = null;
    //if (Number(authInfo.userRole.id) === 2) {
    if (adminView) {
      getConsultingByCompany = await getRequestAllConsultingByCompany();
    } else {
      localCompanyData?.subCompanyID !== ""
        ? (getConsultingByCompany = await getRequestAllConsultingByCompany(
            "",
            localCompanyData.subCompanyID
          ))
        : (getConsultingByCompany = await getRequestAllConsultingByCompany(
            localCompanyData.companyId,
            ""
          ));
    }
    getConsultingByCompany && setConsultingByCompany(getConsultingByCompany);
  };

  const handleAllUserConsultingByCompany = async () => {
    let getUsersByCompany: any = null;
    localCompanyData?.subCompanyID !== ""
      ? (getUsersByCompany = await getRequestAllUserConsulting(
          "",
          localCompanyData.subCompanyID
        ))
      : (getUsersByCompany = await getRequestAllUserConsulting(
          localCompanyData.companyId,
          ""
        ));
    if (getUsersByCompany) {
      setUsersByCompany(getUsersByCompany);
    }
  };

  const handleNumbersInquiries = async () => {
    let getNumberInquiries: any = null;
    localCompanyData?.subCompanyID !== ""
      ? (getNumberInquiries = await getRequestInquiries(
          "",
          localCompanyData.subCompanyID
        ))
      : (getNumberInquiries = await getRequestInquiries(
          localCompanyData.companyId,
          ""
        ));
    if (getNumberInquiries) {
      setNumberInquiries(getNumberInquiries);
    }
  };

  const handleRequestMoreInquiries = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    let responseRequestMoreInquiries: any = null;
    const requestInquiries: any = {
      name: authInfo?.name,
      email: authInfo.username,
      company: localCompanyData?.companyId,
      subcompany: localCompanyData?.subCompanyID,
      numberRequest: data.queries,
    };
    try {
      responseRequestMoreInquiries = await requestMoreInquiries(
        "21",
        requestInquiries
      );
      if (responseRequestMoreInquiries) {
        setSuccessMsg &&
          setSuccessMsg("Solicitud de consultas envíada al administrador");
        setLoading && setLoading(false);
      }
    } catch (error) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg("Error al solicitar más consultas");
    }
  };

  const data = [
    {
      name: "2023",
      data: [1, 8, 6, 41],
    },
  ];

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    const dataName = e.target.name;
    switch (dataName) {
      case "comparationByMonthCompanies":
        await handleFecthComparationCompanies();
        break;
      case "companiesStage":
        await handleFetchTopCompanies();
        break;
      case "moreInquiries":
        await handleRequestMoreInquiries(data);
        break;
      default:
        break;
    }
  };

  /* const adminView = authInfo.userRole.authorityRoles.some(
    (authority: any) => authority.authority.code === "ADMIN:VIEWDASHBOARD"
  );

  const userView = authInfo.userRole.authorityRoles.some(
    (authority: any) => authority.authority.code === "USER:VIEWDASHBOARD"
  ); */

  return (
    <>
      <FancyPaper pagetitle="">
        <Grid container spacing={6}>
          {adminView && (
            <>
              <Grid item md={3} sm={6} xs={12}>
                <CardDashboard
                  positive
                  sx={{ height: "100%" }}
                  value={consultingByCompany ?? 0}
                  title="Total consultas"
                  subtitle=""
                  colorIcon="#43a491"
                  iconCard={<QueryStatsIcon />}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <CardDashboard
                  positive
                  sx={{ height: "100%" }}
                  value={queriesByUserMonthStage ?? 0}
                  title="Total usuarios"
                  subtitle=""
                  //colorIcon="#7fba39"
                  colorIcon="#efa72a"
                  iconCard={<PersonSearchIcon />}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <CardDashboard
                  positive
                  sx={{ height: "100%" }}
                  value={allCompanies ?? 0}
                  title="Total compañías"
                  subtitle=""
                  colorIcon="#7fba39"
                  iconCard={<PersonSearchIcon />}
                />
              </Grid>
              <Grid item md={3} xs={12}></Grid>
              <Grid item md={6} xs={12}>
                {comparationByMonthCompanies !== null && (
                  <ComparativeGraph
                    title="Total consultas por mes de todos los usuarios"
                    chartSeries={comparationByMonthCompanies}
                    sx={{
                      height: "100%",
                      boxShadow:
                        "0px 1px 1px 1px rgba(0,0,0,0.12),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 1px 0px rgba(0,0,0,0.12)",
                      background: "#fafafa",
                    }}
                    handleClick={handleSubmit(onSubmit)}
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <PieChartDashboard
                  title="Compañías que más consultan"
                  data={topCompanies}
                  titleChart=" "
                  handleClick={handleSubmit(onSubmit)}
                  sx={{
                    boxShadow:
                      "0px 1px 1px 1px rgba(0,0,0,0.12),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 1px 0px rgba(0,0,0,0.12)",
                    background: "#fafafa",
                  }}
                />
              </Grid>
            </>
          )}
          {userView && (
            <>
              <Grid item md={3} sm={6} xs={12}>
                <CardDashboard
                  positive
                  sx={{ height: "100%" }}
                  value={consultingByCompany ?? 0}
                  title="Total consultas"
                  subtitle=""
                  colorIcon="#43a491"
                  iconCard={<QueryStatsIcon />}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <CardDashboard
                  positive
                  sx={{ height: "100%" }}
                  value={usersByCompany ?? 0}
                  title="Total usuarios"
                  subtitle=""
                  colorIcon="#efa72a"
                  iconCard={<PersonSearchIcon />}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <CardDashboard
                  positive
                  sx={{ height: "100%" }}
                  inquiries={numberInquiries?.numberInquiries}
                  remainingRequest={numberInquiries?.remainingRequest}
                  title="Consultas restantes"
                  subtitle=""
                  colorIcon="#e56a37"
                  iconCard={<PolicyIcon />}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <CardDashboard
                  positive
                  sx={{ height: "100%" }}
                  title="¿Más consultas?"
                  colorIcon="#7fba39"
                  iconCard={<QueueIcon />}
                  linkButton={handleMoreQueries}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {comparationByMonthCompanies !== null && (
                  <ComparativeGraph
                    title="Total consultas por mes"
                    chartSeries={comparationByMonthCompanies}
                    sx={{
                      height: "100%",
                      boxShadow:
                        "0px 1px 1px 1px rgba(0,0,0,0.12),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 1px 0px rgba(0,0,0,0.12)",
                      background: "#fafafa",
                    }}
                    handleClick={handleSubmit(onSubmit)}
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <PieChartDashboard
                  title="Usuarios que más consultan"
                  data={topUsers}
                  titleChart=" "
                  handleClick={handleSubmit(onSubmit)}
                  sx={{
                    boxShadow:
                      "0px 1px 1px 1px rgba(0,0,0,0.12),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 1px 0px rgba(0,0,0,0.12)",
                    background: "#fafafa",
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
        {modalData?.modalOpen && (
          <Dialog
            open={modalData.modalOpen}
            onClose={handleCancelModal}
            fullWidth
            maxWidth="xs"
          >
            <AddMoreQueries
              rate={50}
              limit={1000}
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
            />
          </Dialog>
        )}
      </FancyPaper>
    </>
  );
};

export default Dashboard;
