import { useState, useEffect } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  styled,
  FormHelperText,
  IconButton,
  useTheme,
  Switch,
  FormControl,
  FormLabel,
  InputAdornment,
  InputLabel,
  Input,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FancyTooltip from "../../components/utils/FancyTooltip";
import { deleteRequestSubcomapny } from "../../services/subcompany/SubcompanyService";
import {
  deleteWebServiceUserRequest,
  activateWebServiceUserRequest,
} from "../../services/company/WebServiceUser";
import { useApp } from "../../hooks/useApp";

import {
  TrashIcon,
  PlusIcon,
  PenIcon,
  ConfigIcon,
} from "../../components/svgicons/SvgIcons";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdate {
  id?: number;
  description: string;
  numberInquiries?: number;
  newQueries?: number;
  massiveRequest: boolean;
  conectApi: boolean;
  webServiceID?: any;
  webServiceUser?: string;
  webServicePass?: string;
  webServiceUserActive?: boolean;
  isRD: boolean;
  internalList: boolean;
  isCRC: boolean;
  tokenCRC: string;
  apiIdCRC: string;
  isJCE: boolean;
  serviceIdJCE: string;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  webServiceUserCompany?: webServiceUserType[] | null;
}

interface ItemType {
  id: number;
  description: string;
  numberInquiries: number;
  massiveRequest: boolean;
  conectApi?: boolean;
  webServiceUser?: string;
  webServicePass?: string;
  companyId?: any;
  newQueries?: any;
  isRD: boolean;
  internalList?: boolean;
  isCRC: boolean;
  tokenCRC: string;
  apiIdCRC: string;
  isJCE: boolean;
  serviceIdJCE: string;
}

interface webServiceUserType {
  id: number;
  username: string;
  active: boolean;
  lastLogin: Date | string;
  password?: string;
  "company-id": number;
  "subcompany-id": number;
}

const AddCompanyModal = ({
  actionButton,
  data,
  webServiceUserCompany,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  //Validación de datos
  //console.log("AddCompanyModal data=",data);
  const theme = useTheme();
  const { setErrorMsg, setLoading, setSuccessMsg } = useApp();

  //const [items, setItems] = useState<ItemType[]|null>(null);
  const [items, setItems] = useState<ItemType[]>(data.addSubCompanies);
  const [webServiceUser, setWebServiceUser] = useState<
    webServiceUserType[] | null
  >(webServiceUserCompany ?? null);
  //console.log("AddCompanyModal  data.addSubCompanies.lenght=", data.addSubCompanies.length);
  const [numberInquirieReadOnly, setNumberInquirieReadOnly] = useState(
    data.addSubCompanies.length
  );

  const [showPassword, setShowPassword] = useState(false);

  const validation = Yup.object().shape({
    description: Yup.string().required("Campo es requerido"),
    numberInquiries: Yup.number().typeError("El campo debe ser numérico"),
    newQueries: Yup.number().typeError("El campo debe ser numérico"),
    massiveRequest: Yup.boolean(),
    conectApi: Yup.boolean(),
    active: Yup.boolean(),
    isRD: Yup.boolean(),
    internalList: Yup.boolean(),
    isCRC: Yup.boolean(),
    tokenCRC: Yup.string().when("isCRC", {
      is: true,
      then: Yup.string().required("Campo es requerido"),
      otherwise: Yup.string(),
    }),
    apiIdCRC: Yup.string().when("isCRC", {
      is: true,
      then: Yup.string().required("Campo es requerido"),
      otherwise: Yup.string(),
    }),
    isJCE: Yup.boolean(),
    serviceIdJCE: Yup.string().when("isJCE", {
      is: true,
      then: Yup.string().required("Campo es requerido"),
      otherwise: Yup.string(),
    }),
    webServiceID: Yup.string(),
    webServiceUser: Yup.string().when("conectApi", {
      is: true,
      then: Yup.string().required("Campo es requerido"),
    }),
    webServicePass: Yup.string().when("conectApi", {
      is: true,
      then: Yup.string().required("Campo es requerido"),
    }),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    description: data.description ? data.description : "",
    numberInquiries: data.numberInquiries ? data.numberInquiries : 0,
    newQueries: data.newQueries ? data.newQueries : 0,
    massiveRequest: data.massiveRequest === null ? false : data.massiveRequest,
    conectApi: data.conectApi === null ? false : data.conectApi,
    isRD: data.isRD === null ? false : data.isRD,
    isCRC: data.isCRC === null ? false : data.isCRC,
    tokenCRC: data.tokenCRC ?? "",
    apiIdCRC: data.apiIdCRC ?? "",
    isJCE: data.isJCE === null ? false : data.isJCE,
    serviceIdJCE: data.serviceIdJCE ?? "",
    internalList: data.internalList === null ? false : data.internalList,
    webServiceID:
      webServiceUser !== null && webServiceUser?.length > 0
        ? webServiceUser.filter(
            (item: webServiceUserType) => item["subcompany-id"] === 0
          )[0]?.id
        : 0,
    webServiceUser:
      webServiceUser !== null && webServiceUser?.length > 0
        ? webServiceUser.filter(
            (item: webServiceUserType) => item["subcompany-id"] === 0
          )[0]?.username
        : "",
    webServicePass:
      webServiceUser !== null && webServiceUser?.length > 0
        ? webServiceUser.filter(
            (item: webServiceUserType) => item["subcompany-id"] === 0
          )[0]?.password
        : "",
    webServiceUserActive:
      webServiceUser !== null && webServiceUser?.length > 0
        ? webServiceUser.filter(
            (item: webServiceUserType) => item["subcompany-id"] === 0
          )[0]?.active
        : false,
    addSubCompanies: [],
  };

  const addItem = async () => {
    try {
      const newItem = {
        id: 0,
        description: "",
        numberInquiries: 0,
        newQueries: 0,
        massiveRequest: false,
        error: 0,
        errorMsg: "", //Campo es requerido
        errorConsultas: 0,
        errorMsgConsultas: "", //Campo es requerido
        conectApi: false,
        isRD: false,
        internalList: false,
        isCRC: false,
        tokenCRC: "",
        apiIdCRC: "",
        isJCE: false,
        serviceIdJCE: "",
        webServiceUser: "",
        webServicePass: "",
        errorWebServiceUser: 0,
        errorMsgWebServiceUser: "",
        errorWebServicePass: 0,
        errorMsgWebServicePass: "",
        errorSomeList: 0,
        errorMsgSomeList: "",
      };

      const addItems = [...items, newItem];
      setItems(addItems);
      setNumberInquirieReadOnly(true);
      const sum = Object.values(addItems).reduce(
        (acc, currentItem) => acc + currentItem.numberInquiries,
        0
      );
      resetField("numberInquiries", {
        defaultValue: sum,
      });
      resetField("newQueries", {
        defaultValue: 0,
      });
      resetField("massiveRequest", {
        defaultValue: false,
      });
      resetField("conectApi", {
        defaultValue: false,
      });
      resetField("internalList", {
        defaultValue: false,
      });
      resetField("isRD", {
        defaultValue: false,
      });
      resetField("isCRC", {
        defaultValue: false,
      });
      resetField("tokenCRC", {
        defaultValue: "",
      });
      resetField("apiIdCRC", {
        defaultValue: "",
      });
      resetField("isJCE", {
        defaultValue: false,
      });
      resetField("serviceIdJCE", {
        defaultValue: "",
      });
      resetField("webServiceUser", {
        defaultValue: "",
      });
      resetField("webServicePass", {
        defaultValue: "",
      });
    } catch (error: any) {
      console.log("handleAddSubCompanies error=", error.message);
    }
  };
  const removeItem = async (index: number) => {
    const deletedItems = items.filter((_, i) => i !== index);
    //console.log("deletedItems=",items[index]);

    if (deletedItems.length === 0) {
      setNumberInquirieReadOnly(false);
      resetField("newQueries", {
        defaultValue: 0,
      });
      /* resetField("numberInquiries", {
        defaultValue: 0,
      }); */
    } else {
      const sum = Object.values(deletedItems).reduce(
        (acc, currentItem) => acc + currentItem.numberInquiries,
        0
      );
      resetField("numberInquiries", {
        defaultValue: sum,
      });
      /* resetField("numberInquiries", {
        defaultValue: sum,
      }); */
    }
    setItems(deletedItems);

    //enviar endpoint de eliminar======
    //@ts-ignore
    const idSubcompany = items[index].id;
    if (idSubcompany !== 0) {
      //console.log("enviar endpoint de eliminar");
      //console.log("idSubcompany: ", idSubcompany);
      const getWebServiceUserSubCompany: any = webServiceUserCompany?.filter(
        //(sub: webServiceUserType) => sub["id"] === 17
        (sub: webServiceUserType) => sub["subcompany-id"] === idSubcompany
      );
      //console.log("getWebServiceUserSubCompany: ", getWebServiceUserSubCompany);
      if (getWebServiceUserSubCompany.length > 0) {
        getWebServiceUserSubCompany[0].id &&
          (await deleteWebServiceUserRequest(
            getWebServiceUserSubCompany[0].id
          ));
      }
      let deleteData = await deleteRequestSubcomapny(idSubcompany);
    }
  };
  const updateItemName = (
    index: number,
    label: any,
    newName: any,
    idWebService?: number | ""
  ) => {
    const updatedItems = [...items];

    if (label === "description") {
      updatedItems[index].description = newName;

      if (newName !== "") {
        //@ts-ignore
        updatedItems[index].error = 0;
        //@ts-ignore
        updatedItems[index].errorMsg = "";
      } else {
        //@ts-ignore
        updatedItems[index].error = 1;
        //@ts-ignore
        updatedItems[index].errorMsg = "Campo es requerido";
      }
    }
    if (label === "conectApi") {
      updatedItems[index].conectApi = newName;

      if (newName !== true) {
        //@ts-ignore
        updatedItems[index].errorWebServiceUser = 0;
        //@ts-ignore
        updatedItems[index].errorMsgWebServiceUser = "";
        //@ts-ignore
        updatedItems[index].errorWebServicePass = 0;
        //@ts-ignore
        updatedItems[index].errorMsgWebServicePass = "";
        idWebService && deleteWebServiceUserRequest(idWebService);
      } else {
        //@ts-ignore
        updatedItems[index].errorWebServiceUser = 1;
        //@ts-ignore
        updatedItems[index].errorMsgWebServiceUser = "Campo es requerido";
        //@ts-ignore
        updatedItems[index].errorWebServicePass = 1;
        //@ts-ignore
        updatedItems[index].errorMsgWebServicePass = "Campo es requerido";
      }
    }
    if (label === "webServiceUser") {
      updatedItems[index].webServiceUser = newName;
      if (newName !== "" || newName !== undefined) {
        //@ts-ignore
        updatedItems[index].errorWebServiceUser = 0;
        //@ts-ignore
        updatedItems[index].errorMsgWebServiceUser = "";
      }
    }
    if (label === "webServicePass") {
      updatedItems[index].webServicePass = newName;
      if (newName !== "" || newName !== undefined) {
        //@ts-ignore
        updatedItems[index].errorWebServicePass = 0;
        //@ts-ignore
        updatedItems[index].errorMsgWebServicePass = "";
      }
    }
    if (label === "massiveRequest") {
      updatedItems[index].massiveRequest = newName;

      if (newName !== "") {
        //@ts-ignore
        updatedItems[index].error = 0;
        //@ts-ignore
        updatedItems[index].errorMsg = "";
      } else {
        //@ts-ignore
        updatedItems[index].error = 1;
        //@ts-ignore
        updatedItems[index].errorMsg = "Campo es requerido";
      }
    }

    if (label === "newQueries") {
      updatedItems[index].newQueries = parseInt(newName);
      //console.log("newName=",newName);
      if (newName !== 0) {
        //@ts-ignore
        updatedItems[index].errorConsultas = 0;
        //@ts-ignore
        updatedItems[index].errorMsgConsultas = "";
      } else {
        //@ts-ignore
        updatedItems[index].errorConsultas = 1;
        //@ts-ignore
        updatedItems[index].errorMsgConsultas = "Campo es requerido";
      }
    }

    if (label === "isRD") {
      updatedItems[index].isRD = newName;

      if (newName === false) {
        //@ts-ignore
        updatedItems[index].errorSomeList = 0;
        //@ts-ignore
        updatedItems[index].errorMsgSomeList = "";
        //@ts-ignore
        items[index].internalList = false;
        items[index].isCRC = false;
        items[index].isJCE = false;
      } else {
        //@ts-ignore
        updatedItems[index].errorSomeList = 1;
        //@ts-ignore
        updatedItems[index].errorMsgSomeList =
          "Al menos uno (1) de estos campos es requerido";
      }
    }
    if (label === "internalList") {
      updatedItems[index].internalList = newName;
      if (newName === true) {
        //@ts-ignore
        updatedItems[index].errorSomeList = 0;
        //@ts-ignore
        updatedItems[index].errorMsgSomeList = "";
      }
    }
    if (label === "isCRC") {
      updatedItems[index].isCRC = newName;
      if (newName === true) {
        //@ts-ignore
        updatedItems[index].errorSomeList = 0;
        //@ts-ignore
        updatedItems[index].errorMsgSomeList = "";
        //@ts-ignore
        items[index].errortokenCRC = 1;
        //@ts-ignore
        items[index].errorMsgtokenCRC = "Campo es requerido";
        //@ts-ignore
        items[index].errorapiIdCRC = 1;
        //@ts-ignore
        items[index].errorMsgapiIdCRC = "Campo es requerido";
      } else {
        updatedItems[index].tokenCRC = "";
        updatedItems[index].apiIdCRC = "";
        //@ts-ignore
        items[index].errortokenCRC = 0;
        //@ts-ignore
        items[index].errorMsgtokenCRC = "";
        //@ts-ignore
        items[index].errorapiIdCRC = 0;
        //@ts-ignore
        items[index].errorMsgapiIdCRC = "";
      }
    }
    if (label === "tokenCRC") {
      updatedItems[index].tokenCRC = newName.trim();
      if (newName !== "") {
        //@ts-ignore
        items[index].errortokenCRC = 0;
        //@ts-ignore
        items[index].errorMsgtokenCRC = "";
      }
    }
    if (label === "apiIdCRC") {
      updatedItems[index].apiIdCRC = newName.trim();
      if (newName !== "") {
        //@ts-ignore
        items[index].errorapiIdCRC = 0;
        //@ts-ignore
        items[index].errorMsgapiIdCRC = "";
      }
    }
    if (label === "isJCE") {
      updatedItems[index].isJCE = newName;
      if (newName === true) {
        //@ts-ignore
        updatedItems[index].errorSomeList = 0;
        //@ts-ignore
        updatedItems[index].errorMsgSomeList = "";
        //@ts-ignore
        items[index].errorserviceIdJCE = 1;
        //@ts-ignore
        items[index].errorMsgserviceIdJCE = "Campo es requerido";
      } else {
        updatedItems[index].serviceIdJCE = "";
        //@ts-ignore
        items[index].errorserviceIdJCE = 0;
        //@ts-ignore
        items[index].errorMsgserviceIdJCE = "";
      }
    }
    if (label === "serviceIdJCE") {
      updatedItems[index].serviceIdJCE = newName.trim();
      if (newName !== "") {
        //@ts-ignore
        items[index].errorserviceIdJCE = 0;
        //@ts-ignore
        items[index].errorMsgserviceIdJCE = "";
      }
    }
    /* if (label === "internalList" || label === "isCRC" || label === "isJCE") {
    } */
    setItems(updatedItems);
  };

  const onSubmit2 = (data: any) => {
    data.addSubCompanies = items;

    if (items.length === 0) {
      onSubmit(data);
    } else {
      let bActive = true;
      for (let i = 0; i < items.length; i++) {
        const obj = items[i];

        const description = obj.description;
        if (description.trim() === "") {
          bActive = false;

          //@ts-ignore
          items[i].error = 1;
          //@ts-ignore
          items[i].errorMsg = "Campo es requerido";
        } else {
          //@ts-ignore
          items[i].error = 0;
          //@ts-ignore
          items[i].errorMsg = "";
        }
        //console.log(obj.numberInquiries)
        if (obj.id === 0 && (obj.newQueries === 0 || isNaN(obj.newQueries))) {
          //console.log("newQueries: ", obj.newQueries);
          bActive = false;
          //@ts-ignore
          items[i].errorConsultas = 1;
          //@ts-ignore
          items[i].errorMsgConsultas = "Campo es requerido";
        } else {
          //@ts-ignore
          items[i].errorConsultas = 0;
          //@ts-ignore
          items[i].errorMsgConsultas = "";
        }
        if (
          obj.conectApi === true &&
          (obj.webServiceUser === "" ||
            obj.webServiceUser === undefined ||
            obj.webServicePass === "" ||
            obj.webServicePass === undefined)
        ) {
          bActive = false;

          if (obj.webServiceUser === "" || obj.webServiceUser === undefined) {
            //@ts-ignore
            items[i].errorWebServiceUser = 1;
            //@ts-ignore
            items[i].errorMsgWebServiceUser = "Campo es requerido";
          } else if (
            obj.webServicePass === "" ||
            obj.webServicePass === undefined
          ) {
            //@ts-ignore
            items[i].errorWebServicePass = 1;
            //@ts-ignore
            items[i].errorMsgWebServicePass = "Campo es requerido";
          } else {
            //@ts-ignore
            items[i].errorWebServiceUser = 0;
            //@ts-ignore
            items[i].errorWebServicePass = 0;
            //@ts-ignore
            items[i].errorMsgWebServiceUser = "";
            //@ts-ignore
            items[i].errorMsgWebServicePass = "";
          }
        } else {
          //@ts-ignore
          items[i].errorWebServiceUser = 0;
          //@ts-ignore
          items[i].errorWebServicePass = 0;
          //@ts-ignore
          items[i].errorMsgWebServiceUser = "";
          //@ts-ignore
          items[i].errorMsgWebServicePass = "";
        }
        if (
          obj.isRD === true &&
          (obj.internalList === false || obj.internalList === null) &&
          (obj.isCRC === false || obj.isCRC === null) &&
          (obj.isJCE === false || obj.isJCE === null)
        ) {
          bActive = false;
          //@ts-ignore
          items[i].errorSomeList = 1;
          //@ts-ignore
          items[i].errorMsgSomeList =
            "Al menos uno (1) de estos campos es requerido";
          /* //@ts-ignore
          items[i].errorInternalList = 1;
          //@ts-ignore
          items[i].errorMsgInternalList = "Campo es requerido"; */
        } else {
          //@ts-ignore
          items[i].errorSomeList = 0;
          //@ts-ignore
          items[i].errorMsgSomeList = "";
        }
        if (
          obj.isCRC === true &&
          obj.apiIdCRC === null &&
          obj.tokenCRC === null
        ) {
          bActive = false;
          //@ts-ignore
          items[i].errortokenCRC = 1;
          //@ts-ignore
          items[i].errorMsgtokenCRC = "Campo es requerido";
          //@ts-ignore
          items[i].errorapiIdCRC = 1;
          //@ts-ignore
          items[i].errorMsgapiIdCRC = "Campo es requerido";
        }
        if (obj.isJCE === true && obj.serviceIdJCE === null) {
          bActive = false;

          //@ts-ignore
          items[i].errorserviceIdJCE = 1;
          //@ts-ignore
          items[i].errorMsgserviceIdJCE = "Campo es requerido";
        }
      }

      console.log("data: ", data);
      if (bActive) onSubmit(data);
    }
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    resetField,
    watch,
    setValue,
  } = useForm<AddOrUpdate>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });
  const watch_conectApi = watch("conectApi");
  const watch_webServiceUserActive = watch("webServiceUserActive");
  const watch_webServiceUser = watch("webServiceUser");
  const watch_webServiceID = watch("webServiceID");
  const watch_isRD = watch("isRD");
  const watch_numberInquiries = watch("numberInquiries");
  const watch_newQueries = watch("newQueries");
  const watch_isJCE = watch("isJCE");
  const watch_isCRC = watch("isCRC");

  useEffect(() => {
    if (
      watch_newQueries !== 0 &&
      watch_newQueries !== undefined &&
      watch_numberInquiries !== null &&
      watch_numberInquiries !== undefined
    ) {
      setValue(
        "numberInquiries",
        Number(defaultValues.numberInquiries) + Number(watch_newQueries)
      );
    }
  }, [watch_newQueries]);

  const handleActiveUser = async (id: string | number, active: boolean) => {
    setLoading && setLoading(true);
    try {
      const activeRequest = await activateWebServiceUserRequest({
        id: id,
        active: active,
      });
      if (!activeRequest) {
        setErrorMsg &&
          setErrorMsg(`Error al ${active ? "activar" : "desactivar"} usuario`);
        setLoading && setLoading(false);
        cancelModal();
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(
          `Usuario ${active ? "activado" : "desactivado"} con éxito`
        );
      cancelModal();
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const updateItemsSubCompany = () => {
    let updateItems: ItemType[] | any = items;
    webServiceUser?.map((user: webServiceUserType) =>
      updateItems.filter(
        (i: ItemType) =>
          i.id === user["subcompany-id"] &&
          ((i.webServiceUser = user.username),
          (i.webServicePass = user.password))
      )
    );

    setItems(updateItems);
  };

  useEffect(() => {
    updateItemsSubCompany();
  }, []);

  useEffect(() => {
    if (!watch_isRD) {
      setValue("internalList", false);
    }
  }, [watch_isRD]);

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? properties.com_parval_label_company_button_update_title
          : properties.com_parval_label_company_button_add_title}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12} md={4}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"numberInquiries"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Número de consultas"
                    type="number"
                    variant="standard"
                    disabled
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    InputProps={{
                      //disabled: numberInquirieReadOnly,
                      inputProps: { min: 1 },
                    }}
                    {...register("numberInquiries")}
                    error={
                      errors.numberInquiries && Boolean(errors.numberInquiries)
                    }
                    helperText={
                      errors.numberInquiries && errors.numberInquiries.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"newQueries"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nuevas consultas"
                    type="number"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    InputProps={{
                      disabled: numberInquirieReadOnly,
                      inputProps: { min: 1 },
                    }}
                    {...register("newQueries")}
                    error={errors.newQueries && Boolean(errors.newQueries)}
                    helperText={errors.newQueries && errors.newQueries.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"massiveRequest"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel
                      component="legend"
                      sx={{ color: theme.palette.secondary.dark }}
                    >
                      Consultas por lote
                    </FormLabel>
                    <Switch
                      checked={/* numberInquirieReadOnly ? false : */ value}
                      disabled={numberInquirieReadOnly}
                      {...register("massiveRequest")}
                      inputProps={{ "aria-label": "controlled" }}
                    />

                    {errors.massiveRequest && (
                      <FormHelperText>
                        {errors.massiveRequest.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"conectApi"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel
                      component="legend"
                      sx={{ color: theme.palette.secondary.dark }}
                    >
                      Conectar con Api
                    </FormLabel>
                    <Switch
                      checked={value}
                      {...register("conectApi")}
                      inputProps={{ "aria-label": "controlled" }}
                      disabled={numberInquirieReadOnly}
                    />

                    {errors.massiveRequest && (
                      <FormHelperText>
                        {errors.massiveRequest.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            {watch_conectApi === true && (
              <>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="webServiceUser"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Usuario web service"
                        type="text"
                        variant="standard"
                        value={value}
                        autoComplete="name3"
                        sx={{ "& input": { pl: "0!important" } }}
                        {...register("webServiceUser")}
                        error={
                          errors.webServiceUser &&
                          Boolean(errors.webServiceUser)
                        }
                        helperText={
                          errors.webServiceUser && errors.webServiceUser.message
                        }
                        inputProps={{
                          autoComplete: "webServiceUser",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    name="webServicePass"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        variant="standard"
                        error={errors.webServicePass ? true : false}
                        fullWidth
                      >
                        <InputLabel htmlFor="standard-adornment-password">
                          Contraseña
                        </InputLabel>
                        <Input
                          id="webServicePass"
                          type={showPassword ? "text" : "password"}
                          sx={{
                            "& input": {
                              pl: "0!important",
                            },
                          }}
                          {...register("webServicePass")}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                //onMouseDown={(e) => e.preventDefault()}
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          autoComplete="webServicePass"
                        />
                        {errors.webServicePass && (
                          <FormHelperText>
                            {errors.webServicePass.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
                  {watch_webServiceUserActive === false ? (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={() => handleActiveUser(watch_webServiceID, true)}
                      disabled={watch_webServiceUser === ""}
                    >
                      Activar
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{ mt: 2 }}
                      onClick={() =>
                        handleActiveUser(watch_webServiceID, false)
                      }
                    >
                      Desactivar
                    </Button>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <Controller
                name={"isRD"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel
                      component="legend"
                      sx={{ color: theme.palette.secondary.dark }}
                    >
                      ¿Es de República Dominicana?
                    </FormLabel>
                    <Switch
                      checked={/* numberInquirieReadOnly ? false : */ value}
                      disabled={numberInquirieReadOnly}
                      {...register("isRD")}
                      inputProps={{ "aria-label": "controlled" }}
                    />

                    {errors.isRD && (
                      <FormHelperText>{errors.isRD.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            {watch_isRD === true && (
              <>
                <Grid item xs={12} md={6}>
                  <Controller
                    name={"internalList"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.secondary.dark }}
                        >
                          Consultas lista interna
                        </FormLabel>
                        <Switch
                          checked={/* numberInquirieReadOnly ? false : */ value}
                          disabled={numberInquirieReadOnly}
                          {...register("internalList")}
                          inputProps={{ "aria-label": "controlled" }}
                        />

                        {errors.internalList && (
                          <FormHelperText>
                            {errors.internalList.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name={"isCRC"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.secondary.dark }}
                        >
                          Consultas CRC
                        </FormLabel>
                        <Switch
                          checked={/* numberInquirieReadOnly ? false : */ value}
                          disabled={numberInquirieReadOnly}
                          {...register("isCRC")}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        {errors.isCRC && (
                          <FormHelperText>
                            {errors.isCRC.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name={"isJCE"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.secondary.dark }}
                        >
                          Consultas JCE
                        </FormLabel>
                        <Switch
                          checked={/* numberInquirieReadOnly ? false : */ value}
                          disabled={numberInquirieReadOnly}
                          {...register("isJCE")}
                          inputProps={{ "aria-label": "controlled" }}
                        />

                        {errors.isJCE && (
                          <FormHelperText>
                            {errors.isJCE.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                {watch_isCRC === true && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="tokenCRC"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="tokenCRC"
                            type="text"
                            variant="standard"
                            value={value}
                            autoComplete="name3"
                            sx={{ "& input": { pl: "0!important" } }}
                            {...register("tokenCRC")}
                            error={errors.tokenCRC && Boolean(errors.tokenCRC)}
                            helperText={
                              errors.tokenCRC && errors.tokenCRC.message
                            }
                            inputProps={{
                              autoComplete: "tokenCRC",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="apiIdCRC"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Api ID"
                            type="text"
                            variant="standard"
                            value={value}
                            autoComplete="name3"
                            sx={{ "& input": { pl: "0!important" } }}
                            {...register("apiIdCRC")}
                            error={errors.apiIdCRC && Boolean(errors.apiIdCRC)}
                            helperText={
                              errors.apiIdCRC && errors.apiIdCRC.message
                            }
                            inputProps={{
                              autoComplete: "apiIdCRC",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                {watch_isJCE === true && (
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="serviceIdJCE"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Api ID"
                          type="text"
                          variant="standard"
                          value={value}
                          autoComplete="name3"
                          sx={{ "& input": { pl: "0!important" } }}
                          {...register("serviceIdJCE")}
                          error={
                            errors.serviceIdJCE && Boolean(errors.serviceIdJCE)
                          }
                          helperText={
                            errors.serviceIdJCE && errors.serviceIdJCE.message
                          }
                          inputProps={{
                            autoComplete: "serviceIdJCE",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>

          {items &&
            items.map((row: any, index: number) => (
              <Grid
                key={index}
                container
                spacing={3}
                maxWidth="lg"
                sx={{ pt: 0, pb: 5 }}
              >
                <Grid item xs={1} md={1}>
                  <TextField
                    fullWidth
                    label="ID"
                    value={row.id}
                    type="text"
                    variant="standard"
                    sx={{ "& input": { pl: "0!important" } }}
                    error={row.error}
                    helperText={row.errorMsg}
                    disabled
                  />
                </Grid>
                <Grid item xs={11} md={5}>
                  <TextField
                    fullWidth
                    label="Sub compañía"
                    value={row.description}
                    type="text"
                    onChange={(event) =>
                      updateItemName(index, "description", event.target.value)
                    }
                    variant="standard"
                    sx={{ "& input": { pl: "0!important" } }}
                    error={row.error}
                    helperText={row.errorMsg}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="N° de consultas"
                    value={row.numberInquiries}
                    type="number"
                    disabled
                    onChange={(event) =>
                      updateItemName(
                        index,
                        "numberInquiries",
                        event.target.value
                      )
                    }
                    variant="standard"
                    sx={{ "& input": { pl: "0!important" } }}
                    //error={row.errorConsultas}
                    //helperText={row.errorMsgConsultas}
                    InputProps={{
                      inputProps: { min: 1 },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Nuevas consultas"
                    value={row.newQueries}
                    type="number"
                    onChange={(event) =>
                      updateItemName(index, "newQueries", event.target.value)
                    }
                    variant="standard"
                    sx={{ "& input": { pl: "0!important" } }}
                    error={row.errorConsultas}
                    helperText={row.errorMsgConsultas}
                    InputProps={{
                      inputProps: { min: 1 },
                      // disabled: data.id === 0,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel
                      component="legend"
                      sx={{ color: theme.palette.secondary.dark }}
                    >
                      Consultas por lote
                    </FormLabel>
                    <Switch
                      checked={row.massiveRequest}
                      onChange={(event) =>
                        updateItemName(
                          index,
                          "massiveRequest",
                          event.target.checked
                        )
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <FormHelperText>
                      {row.errorMassiveRequest && row.errorMsgMassiveRequest}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel
                      component="legend"
                      sx={{ color: theme.palette.secondary.dark }}
                    >
                      Conectar con Api
                    </FormLabel>
                    <Switch
                      checked={row.conectApi}
                      onChange={(event) =>
                        updateItemName(
                          index,
                          "conectApi",
                          event.target.checked,
                          webServiceUser?.filter(
                            (item: webServiceUserType) =>
                              item["subcompany-id"] === row.id
                          )[0]?.id ?? ""
                        )
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    {row.errorConectApi !== 0 && (
                      <FormHelperText>{row.errorMsgConectApi}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                {row.conectApi && (
                  <>
                    <Grid item xs={12} md={4}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Usuario web service"
                        type="text"
                        variant="standard"
                        data-webServiceID={
                          webServiceUser?.filter(
                            (item: webServiceUserType) =>
                              item["subcompany-id"] === row.id
                          )[0]?.id
                        }
                        //value={row.webServiceUser}
                        value={
                          row.webServiceUser ??
                          webServiceUser?.filter(
                            (item: webServiceUserType) =>
                              item["subcompany-id"] === row.id
                          )[0]?.username
                        }
                        autoComplete="name3"
                        sx={{ "& input": { pl: "0!important" } }}
                        onChange={(event) =>
                          updateItemName(
                            index,
                            "webServiceUser",
                            event.target.value
                          )
                        }
                        error={row.errorWebServiceUser}
                        helperText={row.errorMsgWebServiceUser}
                        inputProps={{
                          autoComplete: "webServiceUser",
                          form: {
                            autoComplete: "off",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name="webServicePass"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <FormControl
                            variant="standard"
                            error={row.errorWebServicePass ? true : false}
                            fullWidth
                          >
                            <InputLabel htmlFor="standard-adornment-password">
                              Contraseña
                            </InputLabel>
                            <Input
                              id="webServicePass"
                              type={showPassword ? "text" : "password"}
                              sx={{
                                "& input": {
                                  pl: "0!important",
                                },
                              }}
                              onChange={(event) =>
                                updateItemName(
                                  index,
                                  "webServicePass",
                                  event.target.value
                                )
                              }
                              //value={row.webServicePass}
                              value={
                                row.webServicePass ??
                                webServiceUser?.filter(
                                  (item: webServiceUserType) =>
                                    item["subcompany-id"] === row.id
                                )[0]?.password
                              }
                              error={row.errorWebServicePass}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    //onMouseDown={(e) => e.preventDefault()}
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              autoComplete="webServicePass"
                            />
                            {row.errorWebServicePass === 1 && (
                              <FormHelperText>
                                {row.errorMsgWebServicePass}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
                      {webServiceUser?.filter(
                        (item: webServiceUserType) =>
                          item["subcompany-id"] === row.id
                      )[0]?.active === true ? (
                        <Button
                          variant="contained"
                          color="secondary"
                          data-algo={
                            webServiceUser?.filter(
                              (item: webServiceUserType) =>
                                item["subcompany-id"] === row.id
                            )[0]?.active === false
                          }
                          sx={{ mt: 2 }}
                          onClick={() =>
                            handleActiveUser(
                              webServiceUser?.filter(
                                (item: webServiceUserType) =>
                                  item["subcompany-id"] === row.id
                              )[0]?.id ?? 0,
                              false
                            )
                          }
                          data-webServiceID={
                            webServiceUser?.filter(
                              (item: webServiceUserType) =>
                                item["subcompany-id"] === row.id
                            )[0]?.id
                          }
                        >
                          Desactivar
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{ mt: 2 }}
                          onClick={() =>
                            handleActiveUser(
                              webServiceUser?.filter(
                                (item: webServiceUserType) =>
                                  item["subcompany-id"] === row.id
                              )[0]?.id ?? 0,
                              true
                            )
                          }
                          disabled={
                            webServiceUser?.filter(
                              (item: webServiceUserType) =>
                                item["subcompany-id"] === row.id
                            )[0]?.active ?? true
                          }
                          data-watch_webServiceUser={watch_webServiceUser}
                          data-webServiceID={
                            webServiceUser?.filter(
                              (item: webServiceUserType) =>
                                item["subcompany-id"] === row.id
                            )[0]?.id
                          }
                        >
                          Activar
                        </Button>
                      )}
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel
                      component="legend"
                      sx={{ color: theme.palette.secondary.dark }}
                    >
                      ¿Es de República Dominicana?
                    </FormLabel>
                    <Switch
                      checked={row.isRD}
                      onChange={(event) =>
                        updateItemName(index, "isRD", event.target.checked)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </FormControl>
                </Grid>
                {row.isRD && (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.secondary.dark }}
                        >
                          Consultas lista interna
                        </FormLabel>
                        <Switch
                          checked={row.internalList}
                          onChange={(event) =>
                            updateItemName(
                              index,
                              "internalList",
                              event.target.checked
                            )
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <FormHelperText>
                          {row.errorSomeList ? row.errorMsgSomeList : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.secondary.dark }}
                        >
                          Consultas CRC
                        </FormLabel>
                        <Switch
                          checked={row.isCRC}
                          onChange={(event) =>
                            updateItemName(index, "isCRC", event.target.checked)
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <FormHelperText>
                          {row.errorSomeList ? row.errorMsgSomeList : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {row.isCRC && (
                      <>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Api ID"
                            type="text"
                            variant="standard"
                            value={row.apiIdCRC}
                            autoComplete="name3"
                            sx={{ "& input": { pl: "0!important" } }}
                            onChange={(event) =>
                              updateItemName(
                                index,
                                "apiIdCRC",
                                event.target.value
                              )
                            }
                            error={row.errorapiIdCRC}
                            helperText={row.errorMsgapiIdCRC}
                            inputProps={{
                              autoComplete: "tokenCRC",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            size="small"
                            label="Token CRC"
                            type="text"
                            variant="standard"
                            value={row.tokenCRC}
                            autoComplete="name3"
                            sx={{ "& input": { pl: "0!important" } }}
                            onChange={(event) =>
                              updateItemName(
                                index,
                                "tokenCRC",
                                event.target.value
                              )
                            }
                            error={row.errortokenCRC}
                            helperText={row.errorMsgtokenCRC}
                            inputProps={{
                              autoComplete: "tokenCRC",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} md={6}>
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel
                          component="legend"
                          sx={{ color: theme.palette.secondary.dark }}
                        >
                          Consultas JCE
                        </FormLabel>
                        <Switch
                          checked={row.isJCE}
                          onChange={(event: any) =>
                            updateItemName(index, "isJCE", event.target.checked)
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <FormHelperText>
                          {row.errorSomeList ? row.errorMsgSomeList : ""}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {row.isJCE && (
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Service ID"
                          type="text"
                          variant="standard"
                          value={row.serviceIdJCE}
                          autoComplete="name3"
                          sx={{ "& input": { pl: "0!important" } }}
                          onChange={(event) =>
                            updateItemName(
                              index,
                              "serviceIdJCE",
                              event.target.value
                            )
                          }
                          error={row.errorserviceIdJCE}
                          helperText={row.errorMsgserviceIdJCE}
                          inputProps={{
                            autoComplete: "serviceIdJCE",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      </Grid>
                    )}
                  </>
                )}

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "right", paddingTop: 8 }}
                >
                  <FancyTooltip title="Eliminar" placement="top">
                    <IconButton
                      aria-label="trash"
                      component="label"
                      color="secondary"
                      sx={{
                        "&:hover": {
                          color: theme.palette.secondary.dark,
                        },
                      }}
                      data-name="delete"
                      style={{ paddingTop: 0 }}
                      onClick={() => removeItem(index)}
                    >
                      <TrashIcon />
                    </IconButton>
                  </FancyTooltip>
                </Grid>
              </Grid>
            ))}

          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 0, pb: 5 }}>
            <Grid item md={12} sm={12} xs={12} style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                data-name="create"
                onClick={addItem}
              >
                Agregar sub compañía
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit2)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddCompanyModal;
