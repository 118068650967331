import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import UrlBase from "../../url/Urls"

export const getRequest = async (page: any, filter: any) => {
  let responseData = {};  
  const params = new URLSearchParams([["filter", filter]]); 
  await privateFetch
    .get(
      URL.REQUEST_COMPANY(
        page, 
        UrlBase.registration_x_page
      ),  
      {params}  
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};


export const getRequestMappRisk = async (filter:number) => {
  let responseData = {};  
 
  await privateFetch
    .get(
      URL.REQUEST_COMPANY_MAPPRISK(filter)
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};


export const getRequestCompanyDropDown = async (page: any, filter: any) => {
  let responseData = {};  
  const params = new URLSearchParams([["filter", filter]]); 
  const pagesize:any = 1000;
  await privateFetch
    .get(
      URL.REQUEST_COMPANY(
        page, 
        pagesize
      ),  
      {params}  
    )
    .then(async (response) => {
      responseData = await response.data.content;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const getRequestAll = async (page: string, size:string) => {
  let responseData = {}; 
  await privateFetch
    .get(
      URL.REQUEST_COMPANY(
        page, 
        size
      )  
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id:any) => {
  let responseData = {};
  
  
  await privateFetch
    //.get(URL.REQUEST_COMPANY_BYID(id), {
    .get(URL.REQUEST_COMPANY_MAPPRISK(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.message);
    });
  return responseData;
};

export const createRequest = async (data:any) => {
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_COMPANY_CREATE, JSON.stringify(data), {
        headers: {
            ...CONSTANT.HEADER_TYPE,
        },
      })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
        throw new Error(error.response.data.message);
    });
  return responseData;
};


export const updateRequest = async (data:any) => {
  let responseData = {};    
  const requestBody = {
    id: data.id,
    description: data.description,
    numberInquiries: data.numberInquiries,
    massiveRequest: data.massiveRequest,
    conectApi: data.conectApi,
    newQueries: data.newQueries,
    isRD: data.isRD,
    internalList: data.internalList,
    isCRC: data.isCRC,
    tokenCRC: data.tokenCRC,
    apiIdCRC: data.apiIdCRC,    
    isJCE: data.isJCE,
    serviceIdJCE: data.serviceIdJCE
  };
  await privateFetch
    .put(URL.REQUEST_COMPANY_UPDATE(data.id), JSON.stringify(requestBody),{
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_update_error);
    });
  return responseData;
};

export const deleteRequest = async (id:any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_COMPANY_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log("deleteRequest= ", error)
      throw new Error(error.response.data.message);
      //throw new Error(properties.com_parval_label_role_delete_error);
     
    });
  return responseData;
};
