import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
  Button,
  styled,
} from "@mui/material";
import PropTypes from "prop-types";

const WrapCard = styled(Card)(() => ({
  boxShadow:
    "0px 1px 1px 1px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
}));

const CardDashboard = (props: any) => {
  const {
    sx,
    value,
    iconCard,
    title,
    subtitle,
    colorIcon,
    linkButton,
    inquiries,
    remainingRequest,
  } = props;
  return (
    <WrapCard sx={{ ...sx, background: "#fafafa" }}>
      <CardContent sx={{ background: "#fafafa" }}>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.primary" variant="h5">
              {title}
            </Typography>
            {inquiries ? (
              <Typography
                variant="h4"
                sx={{
                  fontSize: "1.725rem",
                  marginBottom: "2px!important",
                  marginTop: "10px!important",
                }}
              >
                {remainingRequest} <small>de</small> {inquiries}
              </Typography>
            ) : (
              <Typography variant="h4">{value}</Typography>
            )}
          </Stack>
          <Avatar
            sx={{
              backgroundColor: `${colorIcon}`,
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon sx={{ fontSize: "1.5rem" }}>{iconCard}</SvgIcon>
          </Avatar>
        </Stack>
        <Stack>
          <Typography color="info.dark" variant="caption">
            {subtitle}
          </Typography>
        </Stack>
        {linkButton && (
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 4 }}
            onClick={linkButton}
          >
            Solicitar
          </Button>
        )}
      </CardContent>
    </WrapCard>
  );
};

export default CardDashboard;

CardDashboard.prototypes = {
  difference: PropTypes.number,
  positive: PropTypes.bool,
  sx: PropTypes.object,
  value: PropTypes.string.isRequired,
};
