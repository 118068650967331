import { Typography, useTheme, Stack, Paper, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { MappRiskLogo } from "./svgicons/SvgIcons";
import MessageManager from "./utils/MessageManager";
import Loading from "./utils/Loading";
import Footer from "./Footer";
import TitleForm from "./TitleForm";
import { CONSTANT } from "../utils/Constants";
import { useApp } from "../hooks/useApp";
import Logo from "../resources/images/mapprisk_logo.svg";
import Circulos from "../resources/images/circulos.jpg";
import Oficina from "../resources/images/bg-login.jpg";

const Content = styled(Stack)(({ theme }) => ({
  position: "relative",
  minHeight: "100vh",
  padding: theme.spacing(0, 4),
  overflowX: "hidden",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "0",
    top: "0",
    background: `linear-gradient(45deg, rgba(37, 40, 39, 0.5) 25%, rgba(121, 220, 200, 0.5) 100%), url(${Oficina})`,
    backgroundSize: "cover",
    backgroundPosition: "10% 0",
    zIndex: "-1",
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  "&:after": {
    [theme.breakpoints.up("md")]: {
      content: '""',
      position: "absolute",
      width: "50%",
      height: "100%",
      right: "0",
      top: "50%",
      bottom: "50%",
      background: `url(${Circulos})  right center no-repeat`,
      backgroundSize: "90%",
      transform: "translate(40%, -50%)",
      zIndex: "-1",
    },
  },
}));

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
}));

const Image = styled("img")(() => ({
  width: "80px",
}));

interface PaperSessionsType {
  children: any;
  to?: string;
  textLink?: string;
  titleForm?: string;
}

const PaperSessions = ({
  children,
  to,
  textLink,
  titleForm,
}: PaperSessionsType) => {
  const theme = useTheme();
  const { isLoading, successMsg, errorMsg, resetErrorMsg, resetSuccessMsg } =
    useApp();

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}
      <Content
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 0, py: 4 }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={"center"}
          sx={{
            position: {
              md: "absolute",
            },
            left: {
              md: 0,
            },
            top: {
              md: "10px",
            },
          }}
        >
          <Image
            src={Logo}
            alt="MappRisk"
            sx={{ width: "80px", marginX: "1rem" }}
          />
          <Typography
            variant="h5"
            sx={{
              fontSize: "1.5rem",
              color: "#DD9807",
            }}
          >
            MappRisk Client Screening
          </Typography>
        </Stack>

        <Paper
          sx={{
            width: "430px",
            maxWidth: "100%",
            borderRadius: "25px",
            background: theme.palette.primary.contrastText,
            boxShadow:
              "0px 1px 1px 1px rgba(0,0,0,0.12), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 1px 0px rgba(0,0,0,0.12)",
            marginBottom: `${theme.spacing(3)}!important`,
            padding: {
              xs: theme.spacing(8),
              md: `${theme.spacing(8)} ${theme.spacing(12)}`,
            },
          }}
        >
          {titleForm && <TitleForm>{titleForm}</TitleForm>}
          {children}
          {textLink && to && (
            <Typography align="center" sx={{ mt: 6 }} variant="body1">
              <LinkTo to={to}>{textLink}</LinkTo>
            </Typography>
          )}
        </Paper>
        <Footer />
      </Content>
    </>
  );
};

export default PaperSessions;
