import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";
import { useApp } from "../hooks/useApp";
import FancyPaper from "../components/FancyPaper";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import { requestBatchDetails } from "../services/batch/BatchService";
import { useParams } from "react-router-dom";

const BatchListDetail = () => {
  const navigate = useNavigate();
  const { setLoading, setErrorMsg } = useApp();
  const [batchListDetail, setBatchDetail] = useState<any>(null);
  const [page, setPage] = useState(0);
  const { batchId } = useParams();

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0);
    };
    if (!!batchId) {
      dataInit();
    }
  }, [page, batchId]);

  const handleFetchData = async (page: number) => {
    setLoading && setLoading(true);
    try {
      let response = await requestBatchDetails(page, batchId!!);
      console.log(response);
      if (response) {
        setBatchDetail(response);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setBatchDetail(null);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage);
    }
  };

  return (
    <>
      <FancyPaper pagetitle="Consultas / Verificación por lotes">
        <Grid container spacing={8} sx={{ pb: 8, pt: 4 }}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate(-1)}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Primer nombre</TableCell>
                    <TableCell>Segundo nombre </TableCell>
                    <TableCell>Apellido</TableCell>
                    <TableCell>Número de línea</TableCell>
                    <TableCell>Procesado</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batchListDetail !== null &&
                    batchListDetail.content.map((row: any, i: number) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.first_name}</TableCell>
                        <TableCell>{row.middle_name}</TableCell>
                        <TableCell>{row.last_name}</TableCell>
                        <TableCell>{row.line_number}</TableCell>
                        <TableCell>
                          {row.processed ? "Procesado" : "No procesado"}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={!row.processed}
                            onClick={() => {
                              navigate(
                                `/dashboard/batchlist/results/${row.result_id}`
                              );
                            }}
                          >
                            Ver
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  {(batchListDetail === null ||
                    batchListDetail.content.length <= 0) && (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No existen registros
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!!batchListDetail && batchListDetail?.content?.length > 0 && (
              <FancyTablePagination
                count={
                  batchListDetail?.content?.length > 0
                    ? batchListDetail?.content?.length
                    : 0
                }
                rowsPerPage={batchListDetail?.size}
                page={page}
                onPageChange={handleChangePage}
                totalElements={batchListDetail?.totalElements}
                totalPages={batchListDetail?.totalPages}
                numberOfElements={batchListDetail?.numberOfElements}
              />
            )}
          </Grid>
        </Grid>
      </FancyPaper>
    </>
  );
};

export default BatchListDetail;
