import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router";
import {
  Grid,
  Autocomplete,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Button,
} from "@mui/material";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-datepicker/dist/react-datepicker.css";
import { useApp } from "../hooks/useApp";
import { getIndexData } from "../utils/LocalStorageManager";
import { getRequestAll } from "../services/company/CompanyService";
import FancyPaper from "../components/FancyPaper";
import ResourceAccess from "../components/security/ResourceAccess";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import { getRequestSubcomapny } from "../services/subcompany/SubcompanyService";
import { requestBatchByCompany } from "../services/batch/BatchService";

interface options {
  id?: string | number;
  description?: string;
}
interface searchHistorical {
  company: options[];
  startDate?: Date | null;
  endDate?: Date | null;
  subCompany?: options[];
}

const schema_options = {
  id: Yup.string(),
  description: Yup.string(),
};

const BatchList = () => {
  const navigate = useNavigate();
  const { setLoading, setErrorMsg } = useApp();
  const [batchList, setBatchList] = useState<any>(null);
  const [companies, setCompanies] = useState<any>(null);
  const [subCompanies, setSubCompanies] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [localCompanyData, setLocalCompanyData] = useState<null | any>(null);

  const defaultValues: searchHistorical = {
    company: [],
    subCompany: [],
  };

  const validation = Yup.object().shape({
    startDate: Yup.date().typeError(""),
    endDate: Yup.date().typeError(""),
    company: Yup.object().nullable().shape(schema_options).typeError(""),
    subCompany: Yup.object().nullable().shape(schema_options).typeError(""),
  });

  const [filter, setFilter] = useState(defaultValues);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    resetField,
    setValue,
  } = useForm<searchHistorical>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const watch_company: any = watch("company");

  useLayoutEffect(() => {
    const getCompanies = async () => {
      handleFetchCompanies && handleFetchCompanies("0", "100");
    };
    getCompanies();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const getLocalCompanyData: any = await getIndexData("companyData");
      setLocalCompanyData({
        company: {
          id: getLocalCompanyData?.companyId,
        },
        subCompany: {
          id: getLocalCompanyData?.subcompanyId,
        },
      });
    };
    getData();
  }, []);

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, localCompanyData);
    };
    if (localCompanyData !== null && localCompanyData?.company?.id) {
      dataInit();
    }
  }, [page, localCompanyData]);

  useEffect(() => {
    const getSubCompanies = async () => {
      await handleFetchSubCompanies(0, watch_company?.id);
    };
    if (watch_company?.id) {
      getSubCompanies();
    } else {
      setSubCompanies(null);
      resetField("subCompany", { defaultValue: [] });
    }
  }, [watch_company]);

  const handleFetchData = async (page: number, filter: any) => {
    setLoading && setLoading(true);
    try {
      let data = {
        company: filter.company.id ?? localCompanyData.company.id ?? "",
        subCompany:
          filter.subCompany?.id ?? localCompanyData.subCompany.id ?? "",
      };
      let response = await requestBatchByCompany(page, data);
      if (response) {
        setBatchList(response);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setBatchList(null);
    }
  };

  /**
   * Get Companies
   */
  const handleFetchCompanies = async (currentPage: string, size: string) => {
    setLoading && setLoading(true);
    try {
      let data: any = await getRequestAll(currentPage, size);
      if (data) {
        //@ts-ignore
        const cleanedData = data.content.map(
          ({
            numberInquiries,
            createdAt,
            createdBy,
            updatedAt,
            updateBy,
            ...rest
          }: any) => rest
        );
        setCompanies(cleanedData);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleFetchSubCompanies = async (page: number, filter: any) => {
    try {
      let responseSubCompany: any = await getRequestSubcomapny(page, filter);
      if (responseSubCompany) {
        const cleanedData = responseSubCompany.content.map(
          ({ numberInquiries, companyId, ...rest }: any) => rest
        );
        setSubCompanies(cleanedData);
      }
    } catch (error: any) {
      setCompanies(null);
      console.log(error);
    }
  };

  const handleClearFilter = async (e: any) => {
    setFilter(defaultValues);
    resetField("company", {
      defaultValue: [],
    });
    setSubCompanies(null);
    await handleFetchData(0, defaultValues);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, filter);
    }
  };

  const onSubmit = async (data: any) => {
    await handleFetchData(0, data);
  };

  return (
    <>
      <FancyPaper pagetitle="Consultas / Verificación por lotes">
        <Grid container spacing={8} sx={{ pb: 8, pt: 4 }}>
          <ResourceAccess isCode={true} pathOrCode={"CONSUMPTIONHISTORY:WRITE"}>
            <Grid item md={3} sm={6} xs={12}>
              <Controller
                control={control}
                name="company"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                      setFilter({ ...filter, company: newValue });
                    }}
                    options={companies !== null ? companies : []}
                    getOptionLabel={(option: any) => option.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Compañía"
                        variant="standard"
                        error={!!errors.company}
                        helperText={errors.company && errors.company.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Controller
                control={control}
                name="subCompany"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                      setFilter({ ...filter, subCompany: newValue });
                    }}
                    options={subCompanies !== null ? subCompanies : []}
                    getOptionLabel={(option: any) => option?.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Sub compañías"
                        variant="standard"
                        disabled={subCompanies === null}
                        error={!!errors.subCompany}
                        helperText={
                          errors.subCompany && errors.subCompany.message
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end" spacing={2.5}>
                <Button
                  variant="contained"
                  //onClick={handleApplyFilter}
                  onClick={handleSubmit(onSubmit)}
                  color="primary"
                >
                  Buscar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClearFilter}
                >
                  Limpiar
                </Button>
              </Stack>
            </Grid>
          </ResourceAccess>

          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>Nombre de archivo</TableCell>
                    <TableCell>Procesadas</TableCell>
                    <TableCell>Líneas</TableCell>
                    <TableCell>Errores</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {batchList !== null &&
                    batchList.content.map((row: any, i: number) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.user_id}</TableCell>
                        <TableCell>{row.file_name}</TableCell>
                        <TableCell>{row.processed}</TableCell>
                        <TableCell>{row.lines}</TableCell>
                        <TableCell>{row.errors}</TableCell>
                        <TableCell>
                          {new Date(row.created_at).toLocaleString("es-RD", {
                            hour12: true,
                          })}
                        </TableCell>
                        <TableCell>
                          {/* <ResourceAccess isCode={true} pathOrCode={'CONSUMPTIONHISTORY:WRITE'}> */}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              navigate(
                                `/dashboard/batchlist/details/${row.id}`
                              );
                            }}
                          >
                            Ver
                          </Button>
                          {/* </ResourceAccess> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  {(batchList === null || batchList.content.length <= 0) && (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No existen registros
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {!!batchList && batchList?.content?.length > 0 && (
              <FancyTablePagination
                count={
                  batchList?.content?.length > 0
                    ? batchList?.content?.length
                    : 0
                }
                rowsPerPage={batchList?.size}
                page={page}
                onPageChange={handleChangePage}
                totalElements={batchList?.totalElements}
                totalPages={batchList?.totalPages}
                numberOfElements={batchList?.numberOfElements}
              />
            )}
          </Grid>
        </Grid>
      </FancyPaper>
    </>
  );
};

export default BatchList;
