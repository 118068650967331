import {
  HouseIcon,
  GearIcon,
  TableIcon,
  FromBracketIcon,
  FileIcon,
  ChartIcon,
  CircleCheckIcon,
  BussinessManIcon,
} from "../components/svgicons/SvgIcons";
import { properties } from "./Properties_es";

import { MenuItem } from "../types/Menu";

export const MENUITEMS: MenuItem[] = [
  {
    label: "Inicio",
    icon: (
      <HouseIcon
        sx={{ transform: "translateY(-1px)", fontSize: "0.9225rem" }}
      />
    ),
    to: "home",
    permissions: ["is_superuser", "DASHBOARD:READ"],
  },
  {
    label: "Consultas",
    icon: <FileIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Verificación en listas",
        to: "validateclient",
        permissions: ["is_superuser", "VALIDATECLIENT:READ"],
      },
      {
        label: "Verificación por lotes",
        to: "batchlist",
        permissions: ["is_superuser", "VALIDATECLIENT:READ"],
      },
    ],
    permissions: ["is_superuser", "QUERIES:READ"],
  },

  {
    label: "Auditoría",
    icon: <CircleCheckIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Historial de Consumo",
        to: "consumptionhistory",
        permissions: ["is_superuser", "CONSUMPTIONHISTORY:READ"],
      },
    ],
    permissions: ["is_superuser", "AUDITMENU:READ"],
  },

  {
    label: "Mantenimiento",
    icon: <TableIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: "Open API",
        to: "openapi",
        permissions: ["is_superuser", "OPENAPI:READ"],
      },
      {
        label: "Cargar lista interna",
        to: "uploadBlackListDocument",
        permissions: ["is_superuser", "UPLOADBLACKLISTDOCUMENT:READ"],
      },
    ],
    permissions: [
      "is_superuser",
      "MAINTENANCEMENU:READ",
      "UPLOADBLACKLISTDOCUMENT:READ",
    ],
  },

  {
    label: "Configuración",
    icon: (
      <GearIcon sx={{ transform: "translateY(-1px)", fontSize: "1.025rem" }} />
    ),
    to: null,
    expand: true,
    children: [
      {
        label: "Usuarios",
        to: "settinguser",
        permissions: ["is_superuser", "USER:READ"],
      },
      {
        label: "Perfiles",
        to: "settingroles",
        permissions: ["is_superuser", "ROLE:READ"],
      },
      {
        label: "Permisos",
        to: "settingauthority",
        permissions: ["is_superuser", "AUTHORITY:READ"],
      },
      {
        label: "Compañías",
        to: "settingcompanies",
        permissions: ["is_superuser", "COMPANIES:READ"],
      },
    ],
    permissions: ["is_superuser", "SETTINGSMENU:READ"],
  },

  {
    label: properties.com_parval_label_dashboard_menu_logout,
    icon: <FromBracketIcon />,
    to: null,

    permissions: ["is_superuser", "is_user"],
  },
];
