import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  TextField,
  Button,
  Stack,
  Dialog,
} from "@mui/material";
import {
  TrashIcon,
  PlusIcon,
  PenIcon,
  ConfigIcon,
} from "../components/svgicons/SvgIcons";
import { properties } from "../utils/Properties_es";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyPaper from "../components/FancyPaper";

import FancyTablePagination from "../components/utils/FancyTablePagination";
import { useApp } from "../hooks/useApp";
import AddOrUpdateCompanyModal from "../components/utils/AddOrUpdateCompanyModal";

import DeleteModal from "../components/utils/DeleteModal";
import { useForm } from "react-hook-form";

import {
  getRequest,
  createRequest,
  updateRequest,
  findByIdRequest,
  deleteRequest,
} from "../services/company/CompanyService";

import {
  getRequestSubcomapny,
  createRequestSubcomapny,
  updateRequestSubcomapny,
  findByIdRequestSubcomapny,
  deleteRequestSubcomapny,
} from "../services/subcompany/SubcompanyService";

import {
  getWebServiceUserRequest,
  addOrUpdWebServiceUserRequest,
  deleteWebServiceUserRequest,
} from "../services/company/WebServiceUser";

import ResourceAccess from "../components/security/ResourceAccess";

interface AddOrUpdateSubCompanies {
  id?: number;
  description: string;
  numberInquiries?: number;
  companyId: number;
}

let contadorSubCompanies: number = 0;
let totalSubCompanies: number = 0;

const SettingCompanies = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();

  const theme = useTheme();
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);

  const initForm = {
    id: 0,
    description: "",
    subCompany: "",
    numberInquiries: "",
    addSubCompanies: [],
  };

  interface webServiceUserType {
    id: number;
    username: string;
    password?: string;
    active: boolean;
    lastLogin: Date | string;
    "company-id": number;
    "subcompany-id": number;
  }

  const [formData, setFormData] = useState<any>(initForm);
  const [webServiceUserCompany, setWebServiceUserCompany] = useState<
    webServiceUserType[] | null
  >(null);
  const { handleSubmit } = useForm();

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, "");
    };
    dataInit();
  }, []);

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  const handleFetchData = async (currentPage: number, filter?: string) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);
      let data: any = await getRequest(currentPage, filter);

      //console.log(data.content)
      if (data) {
        setCompanyData(data);
      }

      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {
    //console.log("onSubmit data=", data);
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    //console.log("handleAdd data=",data);
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      //crear companias
      /* */
      let createData = await createRequest({
        ...data,
      });
      //@ts-ignore
      const idCompany = createData.id;
      //console.log("handleAdd idCompany=",idCompany);

      /*
      //debug para agregar sub companias de una compania existente
      let  createData = true;
      const idCompany = 51;
      */

      if (!createData) {
        //setErrorMsg && setErrorMsg(properties.com_parval_label_role_save_error);
        setLoading && setLoading(false);
        //console.log("no se envia");
        return;
      }

      //agregar subcompanias
      if (data.addSubCompanies.length === 0 && data.conectApi === true) {
        const createUserwebService: any = await addOrUpdWebServiceUserRequest({
          username: data.webServiceUser,
          password: data.webServicePass,
          companyID: idCompany,
          subCompanyID: 0,
          active: data.active ?? false,
        });
        if (!createUserwebService) {
          setErrorMsg && setErrorMsg(createUserwebService.message);
        }
      } else if (data.addSubCompanies.length != 0) {
        let dataSubCompanies: AddOrUpdateSubCompanies[] = [];
        for (let i = 0; i < data.addSubCompanies.length; i++) {
          const obj = data.addSubCompanies[i];
          //console.log("Obj add SubCompany: ", obj);
          const newItemStr = `{"id":0,"description":"${
            obj.description
          }","numberInquiries":${obj.numberInquiries}, "newQueries":${
            obj.newQueries > 0 ? obj.newQueries : 0
          }, "companyId":{"id":${idCompany}},"conectApi":${
            obj.conectApi
          }, "massiveRequest":${obj.massiveRequest}, "internalList":${
            obj.internalList === null ? false : obj.internalList
          }, "isRD":${obj.isRD === null ? false : obj.isRD}, "isCRC":${
            obj.isCRC === null ? false : obj.isCRC
          }, "tokenCRC":"${obj.tokenCRC}", "apiIdCRC":"${
            obj.apiIdCRC
          }", "isJCE":${
            obj.isJCE === null ? false : obj.isJCE
          }, "serviceIdJCE":"${obj.serviceIdJCE}"}`;
          //console.log("newItemStr: ", newItemStr);
          const newItem = JSON.parse(newItemStr);
          dataSubCompanies.push(newItem);
        }
        //console.log("dataSubCompanies=",dataSubCompanies);
        contadorSubCompanies = 0;
        totalSubCompanies = data.addSubCompanies.length;
        //handleAddSubCompanies(dataSubCompanies);
        let createData: any = await createRequestSubcomapny({
          dataSubCompanies,
        });
        if (createData) {
          for (let i = 0; i < createData.length; i++) {
            const sub = createData[i];
            if (
              data.addSubCompanies[i].conectApi === true &&
              data.addSubCompanies[i].webServiceUser
            ) {
              const createUserwebServiceSubCompany: any =
                await addOrUpdWebServiceUserRequest({
                  username: data.addSubCompanies[i].webServiceUser,
                  password: data.addSubCompanies[i].webServicePass,
                  companyID: idCompany,
                  subCompanyID: sub.id,
                });
            }
          }
        }

        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg(properties.com_parval_label_request_update);

        //call fetch data
        await handleFetchData(0, "");
      } else {
        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg(properties.com_parval_label_request_update);
        //call fetch data
        await handleFetchData(0, "");
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  const handleAddSubCompanies = async (data: any) => {
    try {
      if (contadorSubCompanies >= totalSubCompanies) {
        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg(properties.com_parval_label_request_update);
        //call fetch data
        await handleFetchData(0, "");
      } else {
        const item = data[contadorSubCompanies];
        //console.log("item=", item);
        /**/
        let createData = await createRequestSubcomapny({
          ...item,
        });

        contadorSubCompanies++;
        handleAddSubCompanies(data);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  const handleDeleteUserwebService = async (id: string) => {
    try {
      const deleteUserwebService: any = await deleteWebServiceUserRequest(id);
      if (!deleteUserwebService) {
        setErrorMsg && setErrorMsg(deleteUserwebService.message);
      }
      //call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        modifierUser: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }

      //@ts-ignore
      const idCompany = data.id;
      //console.log("handleUpdate idCompany=",idCompany);
      if (data.addSubCompanies.length === 0) {
        if (data.conectApi === true) {
          const createUserwebService: any = await addOrUpdWebServiceUserRequest(
            {
              username: data.webServiceUser,
              password: data.webServicePass,
              companyID: idCompany,
              subCompanyID: 0,
              id: data.webServiceID,
              active: data.active ?? false,
            }
          );
          if (!createUserwebService) {
            setErrorMsg && setErrorMsg(createUserwebService.message);
          } else {
            setLoading && setLoading(false);
            setSuccessMsg &&
              setSuccessMsg(properties.com_parval_label_request_update);
            //call fetch data
            await handleFetchData(0, "");
          }
        } else {
          data.webServiceID !== undefined &&
            (await handleDeleteUserwebService(data.webServiceID));
        }
      } else if (data.addSubCompanies.length != 0) {
        data.webServiceID !== undefined &&
          data.webServiceID !== "0" &&
          (await handleDeleteUserwebService(data.webServiceID));
        //agragar subcompanias
        let dataSubCompanies: AddOrUpdateSubCompanies[] = [];
        let contRowsAdd = 0;
        for (let i = 0; i < data.addSubCompanies.length; i++) {
          const obj = data.addSubCompanies[i];
          //console.log("SubCompany obj: ", obj);
          if (obj.id === 0) {
            const newItemStr = `{"id":0,"description":"${
              obj.description
            }","massiveRequest":${
              obj.massiveRequest ?? false
            }, "numberInquiries":${
              obj.numberInquiries + obj.newQueries
            }, "newQueries":${
              obj.newQueries > 0 ? obj.newQueries : 0
            }, "companyId":{"id":${idCompany}}, "conectApi":${
              obj.conectApi
            }, "internalList":${
              obj.internalList === null ? false : obj.internalList
            }, "isRD":${obj.isRD === null ? false : obj.isRD}, "isCRC":${
              obj.isCRC === null ? false : obj.isCRC
            }, "tokenCRC":"${obj.tokenCRC}", "apiIdCRC":"${
              obj.apiIdCRC
            }", "isJCE":${
              obj.isJCE === null ? false : obj.isJCE
            }, "serviceIdJCE":"${obj.serviceIdJCE}"}`;

            const newItem = JSON.parse(newItemStr);
            dataSubCompanies.push(newItem);
            contRowsAdd++;
          }
        }
        //console.log("dataSubCompaniesAdd=", dataSubCompanies);

        if (contRowsAdd != 0) {
          //console.log("dataSubCompaniesAdd=", dataSubCompanies);

          let createData: any = await createRequestSubcomapny({
            dataSubCompanies,
          });
          if (createData) {
            for (let i = 0; i < createData.length; i++) {
              const sub = createData[i];

              if (createData[i].conectApi === true) {
                const getSubCompdata = await data.addSubCompanies.filter(
                  (item: any) => sub.description === item.description
                );

                if (getSubCompdata) {
                  const createUserwebServiceSubCompany: any =
                    await addOrUpdWebServiceUserRequest({
                      username: getSubCompdata[0]?.webServiceUser,
                      password: getSubCompdata[0]?.webServicePass,
                      companyID: idCompany,
                      subCompanyID: sub.id,
                      active: getSubCompdata[0]?.active ?? false,
                    });
                }
              }
            }
          }
        }

        //editar subcompanias
        let dataSubCompaniesUpdate: AddOrUpdateSubCompanies[] = [];
        let contRowsEdit = 0;
        for (let i = 0; i < data.addSubCompanies.length; i++) {
          const obj = data.addSubCompanies[i];

          if (obj.id != 0) {
            const newItemStr = `{"id":${obj.id},"description":"${
              obj.description
            }","massiveRequest":${
              obj.massiveRequest ?? false
            },"numberInquiries":${
              obj.numberInquiries + +obj.newQueries
            }, "newQueries":${
              obj.newQueries > 0 ? obj.newQueries : 0
            }, "companyId":{"id":${idCompany}},
            "conectApi":${obj.conectApi}, "internalList":${
              obj.internalList === null ? false : obj.internalList
            }, "isRD":${obj.isRD === null ? false : obj.isRD}, "isCRC":${
              obj.isCRC === null ? false : obj.isCRC
            }, "tokenCRC":"${obj.tokenCRC}", "apiIdCRC":"${
              obj.apiIdCRC
            }", "isJCE":${
              obj.isJCE === null ? false : obj.isJCE
            }, "serviceIdJCE":"${obj.serviceIdJCE}"
          }`;

            const newItem = JSON.parse(newItemStr);

            dataSubCompaniesUpdate.push(newItem);
            contRowsEdit++;
          }
        }
        contadorSubCompanies = 0;
        totalSubCompanies = contRowsEdit;
        if (contRowsEdit != 0) {
          const updateSub = await handleUpdateSubCompanies(
            dataSubCompaniesUpdate
          );
          for (let i = 0; i < dataSubCompaniesUpdate.length; i++) {
            const sub = dataSubCompaniesUpdate[i];

            if (
              data.addSubCompanies[i].conectApi === true &&
              data.addSubCompanies[i].webServiceUser
            ) {
              const createUserwebServiceSubCompany: any =
                await addOrUpdWebServiceUserRequest({
                  username:
                    data.addSubCompanies[i].webServiceUser ??
                    webServiceUserCompany?.filter(
                      (item: webServiceUserType) =>
                        item["subcompany-id"] === sub.id
                    )[0]?.username,
                  password:
                    data.addSubCompanies[i].webServicePass ??
                    webServiceUserCompany?.filter(
                      (item: webServiceUserType) =>
                        item["subcompany-id"] === sub.id
                    )[0]?.password,
                  companyID: idCompany,
                  subCompanyID: sub.id,
                  id:
                    webServiceUserCompany?.filter(
                      (item: webServiceUserType) =>
                        item["subcompany-id"] === sub.id
                    )[0]?.id ?? 0,
                  active:
                    webServiceUserCompany?.filter(
                      (item: webServiceUserType) =>
                        item["subcompany-id"] === sub.id
                    )[0]?.id ?? false,
                });
            }
          }
        }

        if (contRowsEdit === 0) {
          setLoading && setLoading(false);
          setSuccessMsg &&
            setSuccessMsg(properties.com_parval_label_request_update);
          //call fetch data
          await handleFetchData(0, "");
        }
      } else {
        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg(properties.com_parval_label_request_update);
        //call fetch data
        await handleFetchData(0, "");
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdateSubCompanies = async (data: any) => {
    try {
      if (contadorSubCompanies >= totalSubCompanies) {
        setLoading && setLoading(false);
        setSuccessMsg &&
          setSuccessMsg(properties.com_parval_label_request_update);
        //call fetch data
        await handleFetchData(0, "");
      } else {
        const item = data[contadorSubCompanies];
        let updateData = await updateRequestSubcomapny({
          ...item,
        });
        contadorSubCompanies++;
        handleUpdateSubCompanies(data);
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };
  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg && setErrorMsg("asd");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);

    try {
      let companyData: any = await findByIdRequest(id);
      //buscar subcompoanias
      const filter = companyData?.content[0]?.id;
      let dataSubcompanies: any = await getRequestSubcomapny(0, filter);
      companyData.content[0].addSubCompanies = dataSubcompanies.content;
      setFormData(companyData.content[0]);
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleFetchUserWebService = async (
    companyID: string,
    subCompanyID?: any
  ) => {
    setLoading && setLoading(true);
    try {
      //console.log("handleFetchByID id=",id);
      let webServiceUserData: any = await getWebServiceUserRequest(
        companyID,
        subCompanyID
      );
      setWebServiceUserCompany(webServiceUserData);
      setLoading && setLoading(false);
    } catch (error: any) {
      //setErrorMsg && setErrorMsg(error.message);
      console.log(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    //console.log("handleOpenModal id=",id);

    if (modalAction === "update") {
      await handleFetchByID(id);
      await handleFetchUserWebService(id, "");
    }

    if (modalAction === "delete") {
      object = companyData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  return (
    <>
      <FancyPaper pagetitle="Configuración / Compañías">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                Agregar compañía
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <TextField
                placeholder="Filtro de búsqueda"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: "0.2rem 0 0 0.2rem",
                  },
                  "& fieldset": {
                    borderRightWidth: "0",
                  },
                }}
                value={preFilter}
                onChange={(e: any) => {
                  setPreFilter(e.target.value);
                  if (e.target.value === "") {
                    handleFetchData(0, "");
                  }
                }}
                onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                  padding: "1.5px 16px!important",
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell align="center">Número de consultas</TableCell>
                <TableCell align="center">Conectar con api</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyData &&
                companyData?.content?.length > 0 &&
                companyData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.description}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.numberInquiries}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {row.conectApi ? "Sí" : "No"}
                    </TableCell>
                    <TableCell align="center">
                      <ResourceAccess isCode={true} pathOrCode={"ROLE:WRITE"}>
                        <FancyTooltip title="Editar" placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={row.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title="Eliminar" placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!!companyData?.content?.length && (
          <FancyTablePagination
            count={
              companyData?.content?.length > 0
                ? companyData?.content?.length
                : 0
            }
            rowsPerPage={companyData?.size}
            page={page}
            onPageChange={handleChangePage}
            totalElements={companyData?.totalElements}
            totalPages={companyData?.totalPages}
            numberOfElements={companyData?.numberOfElements}
          />
        )}
      </FancyPaper>

      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateCompanyModal
            data={formData}
            webServiceUserCompany={webServiceUserCompany}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}

      {/*  {modalData?.modalType === "update" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateCompanyModal
            data={formData}
            webServiceUserCompany={webServiceUserCompany}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )} */}

      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.nombre}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SettingCompanies;
