import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import React from "react";
import FancyTooltip from "../utils/FancyTooltip";
import { capitalize, formatPropertyName } from "../../utils/UtilsFunctions";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

interface Props {
  typePersonValidate: string;
  listSearch: any;
  page: number;
  handleOpenModal: any;
  setDataSelected: any;
}

export const TableSearch = ({
  typePersonValidate,
  listSearch,
  page,
  handleOpenModal,
  setDataSelected,
}: Props) => {
  const theme = useTheme();

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>No</TableCell>
            <TableCell align="center">Coincidencia</TableCell>
            <TableCell align="center">Nombre</TableCell>
            {typePersonValidate === "Física" && (
              <>
                <TableCell align="center">Fecha de nacimiento</TableCell>
                <TableCell>Género</TableCell>
              </>
            )}

            <TableCell>Número de ID</TableCell>
            <TableCell>Dirección</TableCell>
            <TableCell>País</TableCell>
            <TableCell>Tipo de entidad</TableCell>

            {typePersonValidate === "Física" && (
              <TableCell>Ciudadanía</TableCell>
            )}

            <TableCell align="center">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!listSearch && listSearch ? (
            listSearch
              .slice(page * 10, (page + 1) * 10)
              .map((row: any, i: number) => (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {listSearch.indexOf(row) + 1}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.bestNameScore}%
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {row.entityName}
                  </TableCell>
                  {typePersonValidate === "Física" && (
                    <>
                      <TableCell component="th" scope="row" align="center">
                        {row.entityDetails.additionalInfo.entityAdditionalInfo
                          .filter((item: any) => item.type === "DOB")
                          .slice(0, 2)
                          .map((item: any, index: number) => {
                            return (
                              <span key={index}>
                                {item.value} <br />
                              </span>
                            );
                          })}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.entityDetails.gender}
                      </TableCell>
                    </>
                  )}

                  <TableCell component="th" scope="row">
                    {row.entityDetails.ids.entityID
                      .slice(0, 2)
                      .map((item: any, index: number) => {
                        return (
                          <span key={index}>
                            {item.number} ({formatPropertyName(item.type)}){" "}
                            <br />
                          </span>
                        );
                      })}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {!!row.entityDetails.addresses &&
                      row.entityDetails.addresses.entityAddress
                        .slice(0, 2)
                        .map((item: any, index: number) => {
                          return (
                            <span key={index}>
                              {item.city} {item.stateProvinceDistrict}{" "}
                              {item.country} <br />
                            </span>
                          );
                        })}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {!!row.entityDetails.addresses &&
                      row.entityDetails.addresses.entityAddress
                        .slice(0, 1)
                        .map((item: any, index: number) => {
                          return (
                            <span key={index}>
                              {item.country} <br />
                            </span>
                          );
                        })}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {capitalize(row.entityDetails.entityType)}
                  </TableCell>
                  {typePersonValidate === "Física" && (
                    <TableCell component="th" scope="row" align="center">
                      {row.entityDetails.additionalInfo.entityAdditionalInfo
                        .filter((item: any) => item.type === "CITIZENSHIP")
                        .map((item: any, index: number) => {
                          return (
                            <span key={index}>
                              {item.value} <br />
                            </span>
                          );
                        })}
                    </TableCell>
                  )}

                  <TableCell align="center">
                    <FancyTooltip title={"Ver detalle"} placement="top">
                      <IconButton
                        aria-label="userdetails"
                        component="label"
                        color="primary"
                        sx={{
                          "&:hover": {
                            color: theme.palette.secondary.dark,
                          },
                        }}
                        data-id={row.capacitationId}
                        data-name="userdetails"
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenModal(e);
                          setDataSelected(row);
                        }}
                      >
                        <FormatListBulletedIcon />
                      </IconButton>
                    </FancyTooltip>
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <TableRow
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell
                colSpan={typePersonValidate === "Física" ? 11 : 8}
                align="center"
              >
                No hay coincidencias
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
