import { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router";
import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  useTheme,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  FormHelperText,
  Stack,
  Button,
  paginationClasses,
} from "@mui/material";
import InputLabelToForm from "../../components/InputLabelToForm";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import { useApp } from "../../hooks/useApp";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FancyPaper from "../../components/FancyPaper";
import { requestHistoricByCompany } from "../../services/historical/HistoricalServices";
import { getRequestSubcomapny } from "../../services/subcompany/SubcompanyService";
import { getRequestAll } from "../../services/company/CompanyService";
import {
  formatDateOnlyMonthAndYear,
  formatDate,
} from "../../utils/UtilsFunctions";
import { CONSTANT } from "../../utils/Constants";
import { getIndexData } from "../../utils/LocalStorageManager";
import ResourceAccess from "../../components/security/ResourceAccess";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface options {
  id?: string | number;
  description?: string;
}
interface searchHistorical {
  company: options[];
  startDate?: Date | null;
  endDate?: Date | null;
  subCompany?: options[];
}

const schema_options = {
  id: Yup.string(),
  description: Yup.string(),
};

const ConsumptionHistory = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { setLoading, setErrorMsg } = useApp();
  const [historical, setHistorical] = useState<any>(null);
  const [companies, setCompanies] = useState<any>(null);
  const [subCompanies, setSubCompanies] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [localCompanyData, setLocalCompanyData] = useState<null | any>(null);

  const defaultValues: searchHistorical = {
    company: [],
    startDate: new Date(),
    endDate: new Date(),
    subCompany: [],
  };

  const validation = Yup.object().shape({
    startDate: Yup.date().typeError(""),
    endDate: Yup.date().typeError(""),
    company: Yup.object().nullable().shape(schema_options).typeError(""),
    subCompany: Yup.object().nullable().shape(schema_options).typeError(""),
  });

  const [filter, setFilter] = useState(defaultValues);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    resetField,
    setValue,
  } = useForm<searchHistorical>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const watch_company: any = watch("company");

  useLayoutEffect(() => {
    const getCompanies = async () => {
      handleFetchCompanies && handleFetchCompanies("0", "100");
    };
    getCompanies();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const getLocalCompanyData: any = await getIndexData("companyData");
      setLocalCompanyData({
        company: {
          id: getLocalCompanyData?.companyId,
        },
        subCompany: {
          id: getLocalCompanyData?.subcompanyId,
        },
      });
    };
    getData();
  }, []);

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, localCompanyData);
    };
    if (localCompanyData !== null && localCompanyData?.company?.id) {
      dataInit();
    }
  }, [localCompanyData]);

  useEffect(() => {
    const getSubCompanies = async () => {
      await handleFetchSubCompanies(0, watch_company?.id);
    };
    if (watch_company?.id) {
      getSubCompanies();
    } else {
      setSubCompanies(null);
      resetField("subCompany", { defaultValue: [] });
    }
  }, [watch_company]);

  const handleFetchData = async (page: number, filter: any) => {
    setLoading && setLoading(true);
    try {
      let data = {
        company: filter.company?.id ?? localCompanyData.company.id ?? "",
        subCompany:
          localCompanyData.subCompany?.id ?? filter.subCompany?.id ?? "",
        startDate: filter.startDate
          ? formatDateOnlyMonthAndYear(filter.startDate)
          : formatDateOnlyMonthAndYear(new Date()),
        endDate: filter.endDate
          ? formatDateOnlyMonthAndYear(filter.endDate)
          : formatDateOnlyMonthAndYear(new Date()),
      };
      let response = await requestHistoricByCompany(page, data);
      if (response) {
        setHistorical(response);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      console.error(error);
      setLoading && setLoading(false);
      setHistorical(null);
    }
  };

  /**
   * Get Companies
   */
  const handleFetchCompanies = async (currentPage: string, size: string) => {
    setLoading && setLoading(true);
    try {
      let data: any = await getRequestAll(currentPage, size);
      if (data) {
        //@ts-ignore
        const cleanedData = data.content.map(
          ({
            numberInquiries,
            createdAt,
            createdBy,
            updatedAt,
            updateBy,
            ...rest
          }: any) => rest
        );
        setCompanies(cleanedData);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error);
    }
  };

  const handleFetchSubCompanies = async (page: number, filter: any) => {
    try {
      let responseSubCompany: any = await getRequestSubcomapny(page, filter);
      if (responseSubCompany) {
        const cleanedData = responseSubCompany.content.map(
          ({ numberInquiries, companyId, ...rest }: any) => rest
        );
        setSubCompanies(cleanedData);
      }
    } catch (error: any) {
      setCompanies(null);
      console.log(error);
    }
  };

  const handleClearFilter = async (e: any) => {
    setFilter(defaultValues);
    resetField("company", {
      defaultValue: [],
    });
    setSubCompanies(null);
    await handleFetchData(0, defaultValues);
    setPage(0);
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, filter);
      setPage(customPage);
    }
  };

  const onSubmit = async (data: any) => {
    setPage(0);
    await handleFetchData(0, data);
  };

  return (
    <>
      <FancyPaper pagetitle="Auditoría / Historial de Consumo">
        <Grid container spacing={8} sx={{ pb: 8, pt: 4 }}>
          <ResourceAccess isCode={true} pathOrCode={"CONSUMPTIONHISTORY:WRITE"}>
            <Grid item md={3} sm={6} xs={12}>
              <Controller
                control={control}
                name="company"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                      setFilter({ ...filter, company: newValue });
                    }}
                    options={companies !== null ? companies : []}
                    getOptionLabel={(option: any) => option.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Compañía"
                        variant="standard"
                        error={!!errors.company}
                        helperText={errors.company && errors.company.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Controller
                control={control}
                name="subCompany"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                      setFilter({ ...filter, subCompany: newValue });
                    }}
                    options={subCompanies !== null ? subCompanies : []}
                    getOptionLabel={(option: any) => option?.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option?.id === value?.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Sub compañías"
                        variant="standard"
                        disabled={subCompanies === null}
                        error={!!errors.subCompany}
                        helperText={
                          errors.subCompany && errors.subCompany.message
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </ResourceAccess>
          <Grid item md={3} sm={6} xs={12} sx={{ marginTop: "16px" }}>
            <Controller
              name={"startDate"}
              control={control}
              defaultValue={new Date()}
              render={({ field, ...props }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors.startDate && Boolean(errors.startDate)}
                  >
                    Fecha desde
                  </InputLabelToForm>
                  <DatePicker
                    locale="es"
                    minDate={new Date(new Date().getFullYear(), 0, 1)}
                    maxDate={new Date()}
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                      setFilter({ ...filter, startDate: date });
                    }}
                    dateFormat={CONSTANT.DATE_FORMAT_MONTH}
                    showMonthYearPicker
                  />
                </FormControl>
              )}
              {...(errors.startDate && (
                <FormHelperText>{errors.startDate.message}</FormHelperText>
              ))}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12} sx={{ marginTop: "16px" }}>
            <Controller
              name={"endDate"}
              control={control}
              defaultValue={new Date()}
              render={({ field, ...props }) => (
                <FormControl fullWidth>
                  <InputLabelToForm
                    error={errors.endDate && Boolean(errors.endDate)}
                  >
                    Fecha hasta
                  </InputLabelToForm>
                  <DatePicker
                    locale="es"
                    minDate={new Date(new Date().getFullYear(), 0, 1)}
                    maxDate={new Date()}
                    selected={field.value}
                    onChange={(date) => {
                      field.onChange(date);
                      setFilter({ ...filter, endDate: date });
                    }}
                    dateFormat={CONSTANT.DATE_FORMAT_MONTH}
                    showMonthYearPicker
                  />
                </FormControl>
              )}
              {...(errors.endDate && (
                <FormHelperText>{errors.endDate.message}</FormHelperText>
              ))}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end" spacing={2.5}>
              <Button
                variant="contained"
                //onClick={handleApplyFilter}
                onClick={handleSubmit(onSubmit)}
                color="primary"
              >
                Buscar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearFilter}
              >
                Limpiar
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography
              color="inherit"
              sx={{ fontSize: "1.5em", flexGrow: 1, mb: 3 }}
              noWrap
              component="div"
            >
              Consultas realizadas por usuario
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <ResourceAccess
                      isCode={true}
                      pathOrCode={"CONSUMPTIONHISTORY:WRITE"}
                    >
                      <TableCell>Compañía</TableCell>
                      <TableCell>SubCompañía</TableCell>
                      <TableCell>Tipo de Consulta</TableCell>
                      <TableCell>Total de búsquedas</TableCell>
                      <TableCell>Búsquedas a la BD</TableCell>
                      <TableCell>Búsquedas a la API</TableCell>
                    </ResourceAccess>
                    <TableCell>Fecha</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historical !== null &&
                    historical.content.map((row: any, i: number) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>{row.user}</TableCell>
                        <ResourceAccess
                          isCode={true}
                          pathOrCode={"CONSUMPTIONHISTORY:WRITE"}
                        >
                          <TableCell>{row.companyName}</TableCell>
                          <TableCell>{row.subCompanyName}</TableCell>
                          <TableCell>
                            {!!row.individualRequest ? "Individual" : "Lote"}
                          </TableCell>
                          <TableCell>{row.total}</TableCell>
                          <TableCell>{row.databaseRequest}</TableCell>
                          <TableCell>{row.externalRequest} </TableCell>
                        </ResourceAccess>
                        <TableCell>{row.createdAt}</TableCell>
                      </TableRow>
                    ))}
                  {(historical === null || historical.content.length <= 0) && (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        No existen registros
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* {!!historical && historical?.content?.length > 0 && ( */}
            {!!historical && (
              <FancyTablePagination
                count={
                  historical?.content?.length > 0
                    ? historical?.content?.length
                    : 0
                }
                rowsPerPage={historical?.size ?? 0}
                page={page}
                onPageChange={handleChangePage}
                totalElements={historical?.totalElements}
                totalPages={historical?.totalPages}
                numberOfElements={historical?.numberOfElements}
              />
            )}
          </Grid>
        </Grid>
      </FancyPaper>
    </>
  );
};

export default ConsumptionHistory;
