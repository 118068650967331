import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { formatDate } from "../../utils/UtilsFunctions";
import UrlBase from "../../url/Urls"


export const requestBatchByCompany = async (page:number, filter:any) => {
  let responseData = {};
  const params = new URLSearchParams([    
    ["companyId", filter.company ?? ""],
    ["subCompanyId", filter.subCompany ?? ""],
  ]);    
  await privateFetch
    .get(
      URL.REQUEST_BATCH_BY_COMPANY(   
        page,
        UrlBase.registration_x_page      
      ),  
      { params }  
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
     
    });
  return responseData;
};

export const requestBatchDetails = async (page: number, batchId: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_BATCH_DETAILS(batchId, page, UrlBase.registration_x_page))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
}

export const requestBatchResult = async (page: number, resultId: string) => {
  let responseData = {};
 
  await privateFetch
    .get(URL.REQUEST_BATCH_RESULT(resultId, page, UrlBase.registration_x_page))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
}

export const uploadDocumentBatch = async (file: any, userId: string, companyId: number, subCompanyId: number) => {
  
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await privateFetch.post(
      URL.REQUEST_BATCH_UPLOAD(userId, companyId, subCompanyId),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.log(error);
    throw new Error(error.response.data.missing);
  }
}

