import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  useTheme,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import PaperSessions from "../../components/PaperSessions";
import { RecoveryPassword } from "../../types/RecoveryPassword";
import { SVChangePassRequest } from "../../services/security/SecurityService";
import { properties } from "../../utils/Properties_es";

const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const { code } = useParams();
  const { setLoading, setErrorMsg, setSuccessMsg } = useApp();

  const validation = Yup.object().shape({
    password: Yup.string().required("Contraseña es requerido"),
    repeatPassword: Yup.string().required("Confirmar contraseña es requerido"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<RecoveryPassword>({
    resolver: yupResolver(validation),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = async (data: any) => {
    try {
      setLoading && setLoading(true);
      const resultData: any = await SVChangePassRequest({
        id: code,
        password: data.password,
      });

      setSuccessMsg && setSuccessMsg(resultData.info);
      setLoading && setLoading(false);
      navigate("/", { replace: true });
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg &&
        setErrorMsg(
          error.message
            ? error.message
            : properties.com_parval_label_login_general_error
        );
    }
  };

  return (
    <PaperSessions
      to="/"
      textLink="Regresar a inicio de sesión"
      titleForm="Recuperar contraseña"
    >
      <form>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name={"password"}
              control={control}
              render={() => (
                <TextField
                  fullWidth
                  size="small"
                  label="Contraseña"
                  type={showPassword ? "text" : "password"}
                  variant="standard"
                  autoComplete="password"
                  {...register("password")}
                  error={errors.password && Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name={"repeatPassword"}
              control={control}
              render={() => (
                <TextField
                  fullWidth
                  size="small"
                  label="Confirmar Contraseña"
                  type={showPassword ? "text" : "password"}
                  variant="standard"
                  autoComplete="repeatPassword"
                  {...register("repeatPassword")}
                  error={
                    errors.repeatPassword && Boolean(errors.repeatPassword)
                  }
                  helperText={
                    errors.repeatPassword && errors.repeatPassword.message
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              data-name="update"
              name="update"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              sx={{
                borderRadius: "0.25rem",
                mt: 3,
              }}
            >
              Enviar{" "}
            </Button>
          </Grid>
        </Grid>
      </form>
    </PaperSessions>
  );
};

export default ForgotPassword;
