import {
  useState,
  useEffect,
  createContext,
  useMemo,
  useLayoutEffect,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { requestTypes } from "../types/Context";
import { setAuthData, getItem } from "../utils/LocalStorageManager";
import jwt_decode from "jwt-decode";
import UrlBase from "../url/Urls";
import { SVSessionValidateRequest } from "../services/security/SecurityService";
import {
  RequestCompanyRelation,
  RequestSubCompanyFilter,
} from "../services/relationcompany/RelationCompanyService";
import {
  getRequestAll,
  getRequestMappRisk,
  findByIdRequest,
} from "../services/company/CompanyService";

const initialState = {
  isLoading: false,
  errorMsg: "",
  successMsg: "",
};

const AppContext = createContext<requestTypes>(initialState);
const AppProvider = ({ children, authData }: any) => {
  const [authInfo, setAuthInfo] = useState(authData);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: "",
    modalObject: null,
  });
  const [haveCompany, setHaveCompany] = useState(false);
  const [subCompanyData, setSubCompanyData] = useState<any>(null);

  const [authenticate, setAuthenticate] = useState(authData !== null);

  const navigate = useNavigate();
  const { redirect } = useParams();

  const url = window.location.href;

  //console.log(url, "url en AppContext")
  const redirectUrl = url.includes("login")
    ? `/dashboard/batchlist/details/${redirect}`
    : "/dashboard/home";

  //console.log(redirectUrl, "redirectUrl en AppContext")

  const loginProvider = async (loginData: any) => {
    try {
      const sessionCode = loginData.authCode;
      const email = loginData.username;
      if (sessionCode && email) {
        const loginDataValidate = await SVSessionValidateRequest(
          email,
          sessionCode
        );
        //save login data in storage
        const authData = await setAuthData(
          "authMappRiskAdmin",
          loginDataValidate
        );
        if (!authData) {
          setAuthenticate(false);
          return;
        }
        const decodeToken: any = await jwt_decode(
          authData?.tokenInfo?.access_token
        );
        const expiresAt = await decodeToken?.exp;
        let valid = new Date().getTime() / 1000 < expiresAt;
        if (valid) {
          setAuthenticate(valid);
          setAuthInfo(authData);

          if (
            !haveCompany &&
            (authData.userRole.id === 2 || authData.userRole.id === 6)
          ) {
            setHaveCompany(true);
            let dataCompany: any = await getItem("companyDataAdmin");
            const getDataCompany: any = await findByIdRequest(
              Number(dataCompany.companyId)
            );
            await setAuthData("companyDataAdmin", {
              ...dataCompany,
              companyAllData: getDataCompany?.content[0],
            });

            let filter = {
              userId: authData.id,
            };
            const loadSubCompanyDataByUser: any = await RequestCompanyRelation(
              0,
              50,
              filter
            );
            let subCompanyList: any = null;
            let filteredSubCompanies: any = null;
            if (loadSubCompanyDataByUser.content.length > 0) {
              subCompanyList = await RequestSubCompanyFilter(
                0,
                100,
                loadSubCompanyDataByUser.content[0].companyId
              );

              filteredSubCompanies = await filterSubCompanies(
                subCompanyList.content,
                loadSubCompanyDataByUser.content
              );

              /* console.log(
                filteredSubCompanies,
                "filteredSubCompanies en AppContext"
              ); */
              setLoading && setLoading(false);
              if (filteredSubCompanies.length > 1) {
                //console.log(filteredSubCompanies);
                setSubCompanyData(filteredSubCompanies);
              } else if (filteredSubCompanies.length === 1) {
                setSubCompanyData(null);
                //console.log(filteredSubCompanies);
                await setAuthData("companyData", {
                  companyId:
                    Number(filteredSubCompanies[0].companyId.id) ?? null,
                  companyName:
                    filteredSubCompanies[0]?.companyId?.description ?? "",
                  subcompanyId: Number(filteredSubCompanies[0].id) ?? null,
                  subCompanyName: filteredSubCompanies[0]?.description ?? "",
                  numberInquiries:
                    Number(filteredSubCompanies[0]?.numberInquiries) ?? "",
                  userRole: loadSubCompanyDataByUser?.content?.filter(
                    (item: any) =>
                      item.subcompanyId === Number(filteredSubCompanies[0].id)
                  ),
                });
                //console.log("aqui entra al dashboard con 1 sola sub");
                navigate(redirectUrl, {
                  replace: true,
                });
              } else {
                setSubCompanyData(null);
                //console.log(loadSubCompanyDataByUser);
                const mappriskData: any = await getRequestMappRisk(
                  Number(loadSubCompanyDataByUser.content[0].companyId)
                );
                //console.log(mappriskData?.content[0]);
                await setAuthData("companyData", {
                  companyId:
                    Number(loadSubCompanyDataByUser.content[0].companyId) ??
                    null,
                  companyName:
                    subCompanyList.content[0]?.companyId?.description ?? "",
                  subcompanyId: loadSubCompanyDataByUser.content[0].subCompanyId
                    ? Number(loadSubCompanyDataByUser.content[0].subCompanyId)
                    : null,
                  subCompanyName:
                    subCompanyList.content?.filter(
                      (sub: any) =>
                        sub.id ===
                        Number(loadSubCompanyDataByUser.content[0].subCompanyId)
                    )?.description ?? "",
                  numberInquiries:
                    Number(filteredSubCompanies[0]?.numberInquiries) ?? "",
                  massiveRequest:
                    mappriskData?.content[0]?.massiveRequest ?? false,
                  userRole: loadSubCompanyDataByUser?.content?.filter(
                    (item: any) =>
                      item.subcompanyId ===
                      Number(loadSubCompanyDataByUser.content[0].subCompanyId)
                  ),
                });
                //console.log("aqui entra al dashboard con solo company sin sub");
                navigate(redirectUrl);
              }
              return;
            } else {
              await setAuthData("companyData", {
                companyId:
                  Number(loadSubCompanyDataByUser.content[0].companyId) ?? null,
                companyName: subCompanyList.content[0]?.description ?? "",
                subcompanyId: loadSubCompanyDataByUser.content[0].subCompanyId
                  ? Number(loadSubCompanyDataByUser.content[0].subCompanyId)
                  : null,
              });
              navigate(redirectUrl);
              return;
            }
          }
          if (authData.userRole.id === 1) {
            let dataCompany: any = await getItem("companyDataAdmin");
            const getDataCompany: any = await findByIdRequest(
              Number(dataCompany.companyId)
            );
            await setAuthData("companyDataAdmin", {
              ...[dataCompany],
              companyAllData: getDataCompany?.content[0],
            });

            let filter = {
              userId: authData.id,
            };
            const loadSubCompanyDataByAdmin: any = await RequestCompanyRelation(
              0,
              50,
              filter
            );
            /* console.log(
              "loadSubCompanyDataByAdmin: ",
              loadSubCompanyDataByAdmin
            ); */
            if (loadSubCompanyDataByAdmin) {
              await setAuthData("companyData", {
                companyId:
                  Number(loadSubCompanyDataByAdmin.content[0].companyId) ??
                  null,
                companyName:
                  loadSubCompanyDataByAdmin.content[0]?.companyId
                    ?.description ?? "",
              });
            }
          }
          navigate(redirectUrl);
          return;
        }
      }
    } catch (error) {
      console.log(error);
    }
    //setAuthenticate(false);
  };

  const filterSubCompanies = async (subCompanies: any, userCompanies: any) => {
    return subCompanies?.filter((subCompany: any) => {
      const found = userCompanies.find((userCompany: any) => {
        return userCompany.subcompanyId === subCompany.id;
      });
      return found !== undefined;
    });
  };

  const logoutProvider = async (e: any) => {
    //e.preventDefault();
    await setAuthData("authMappRiskAdmin", null);
    await setAuthData("companyData", null);
    await setAuthData("companyDataAdmin", null);
    setAuthenticate(false);
    setHaveCompany(false);
    setSubCompanyData(null);
    navigate("/", { replace: true });
  };

  // useEffect(() => {
  //   let time = 300000;
  //   let timeOutTimer = setTimeout(logoutProvider, time);
  //   const checkInactivity = () => {
  //     window.onclick = () => checkInactivity();
  //     window.onkeypress = () => checkInactivity();
  //     window.addEventListener("mousemove", checkInactivity);
  //     clearTimeout(timeOutTimer);
  //     timeOutTimer = setTimeout(logoutProvider, time);
  //   };
  //   checkInactivity();
  // }, []);

  /**
   * resetErrorMsg
   */
  const resetErrorMsg = () => {
    setErrorMsg("");
  };

  /**
   * resetSuccessMsg
   */
  const resetSuccessMsg = () => {
    setSuccessMsg("");
  };

  const formContextValues: requestTypes = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      setSuccessMsg,
      modalData,
      setModalData,
      loginProvider,
      logoutProvider,
      resetErrorMsg,
      resetSuccessMsg,
      haveCompany,
      subCompanyData,
    }),
    // eslint-disable-next-line
    [authInfo, authenticate, isLoading, errorMsg, successMsg, modalData]
  );

  return (
    <AppContext.Provider value={formContextValues}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider };

export default AppContext;
