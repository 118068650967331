import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";


export const getWebServiceUserRequest = async (companyID: string, subCompanyID?: any) => {
  let responseData = {};    
  const params = new URLSearchParams([    
    ["company-id", companyID],
    ["subcompany-id", subCompanyID]
  ]);  
  let keysParams:any = [];
  params.forEach((value, key) => {
    if (value === '' || value === null) {
      keysParams.push(key);
    }
  });
  keysParams.forEach((key:any) => {
    params.delete(key);
  });  

  await privateFetch
    .get(URL.REQUEST_WEBSERVICEUSER(0),{ params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const addOrUpdWebServiceUserRequest = async (data:any) => {
  let responseData = {};  
  const requestBody = {  
    id: data.id ?? 0,
    username: data.username,
    password: data.password,
    "company-id": data.companyID,
    "subcompany-id": data.subCompanyID ?? 0,
    active: data.active ?? false,
  }; 
  if (requestBody.id === 0) {
    await privateFetch    
    .post(URL.REQUEST_WEBSERVICEUSER(requestBody.id), JSON.stringify(requestBody), {
        headers: {
            ...CONSTANT.HEADER_TYPE,
        },
      })

    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
        throw new Error(error.response.data.message);
    });
  } else {
    await privateFetch    
    .patch(URL.REQUEST_WEBSERVICEUSER(requestBody.id), JSON.stringify(requestBody), {
        headers: {
            ...CONSTANT.HEADER_TYPE,
        },
      })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
        throw new Error(error.response.data.message);
    });
  }
  
  return responseData;
};


export const deleteWebServiceUserRequest = async (id:any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_WEBSERVICEUSER(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log("deleteRequest= ", error)
      throw new Error(error.response.data.message);
     
    });
  return responseData;
};

export const activateWebServiceUserRequest = async (data:any) => {
  let responseData = {};
  await privateFetch    
    .post(URL.REQUEST_WEBSERVICEUSER_ACTIVATE, JSON.stringify(data), {
      headers: {
          ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      console.log("deleteRequest= ", error)
      throw new Error(error.response.data.message);
     
    });
  return responseData;
};