import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { formatDate } from "../../utils/UtilsFunctions";
import UrlBase from "../../url/Urls"


export const requestHistoricByCompany = async (page:number, filter:any) => {
  let responseData = {};
  const params = new URLSearchParams([    
    ["company", filter.company ?? ""],
    ["subCompany", filter.subCompany ?? ""],
    ["endDate", filter.endDate ?? ""],
    ["startDate", filter.startDate ?? ""],
  ]);    
  await privateFetch
    .get(
      URL.REQUEST_HISTORIC_BY_COMPANY(   
        page,
        //"3"    
        UrlBase.registration_x_page      
      ),  
      { params }  
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
     
    });
  return responseData;
};