import { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  defer,
} from "react-router-dom";
import DashboardHome from "../layouts/DashboardHome";

import Login from "../pages/login/Login";
import RememberPassword from "../pages/login/RememberPassword";
import ActiveRegister from "../pages/session/ActiveRegister";
import ForgotPassword from "../pages/login/ForgotPassword";

import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";
import Dashboard from "../pages/Dashboard";
import ValidateClient from "../pages/ValidateClient";
import ConsumptionHistory from "../pages/audit/ConsumptionHistory";
import OpenApi from "../pages/maintenance/OpenApi";
import SettingUser from "../pages/SettingUser";
import SettingRoles from "../pages/SettingRoles";
import SettingCompanies from "../pages/SettingCompanies";
import SettingAuthority from "../pages/SettingAuthority";
import SettingAuthorityByRoleManagement from "../pages/SettingAuthorityByRoleManagement";
import BatchList from "../pages/BatchList";
import BatchListResult from "../pages/BatchListResult";
import BatchListDetail from "../pages/BatchListDetail";
import UploadBlackListDocument from "../pages/UploadBlackListDocument";

const Session = lazy(() => import("../pages/session/Session"));
const NotFound = lazy(() => import("../pages/utils/NotFound"));

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/" element={<Login />} />
      <Route path="/login/:redirect" element={<Login />} />
      <Route path="/rememberpassword" element={<RememberPassword />} />
      <Route
        path="/login/:redirect/rememberpassword"
        element={<RememberPassword />}
      />
      <Route
        path="/forgot-password/:code/:email"
        element={<ForgotPassword />}
      />
      <Route
        path="/active-register/:code/:email"
        element={<ActiveRegister />}
      />
      <Route path="/dashboard" element={<DashboardHome />}>
        <Route path="home" element={<Dashboard />} />
        <Route path="batchlist" element={<BatchList />} />
        <Route
          path="batchlist/details/:batchId"
          element={<BatchListDetail />}
        />
        <Route
          path="batchlist/results/:resultId"
          element={<BatchListResult />}
        />
        <Route path="validateclient" element={<ValidateClient />} />
        <Route
          path="uploadBlackListDocument"
          element={<UploadBlackListDocument />}
        />
        <Route path="consumptionhistory" element={<ConsumptionHistory />} />
        <Route path="openapi" element={<OpenApi />} />
        <Route path="settinguser" element={<SettingUser />} />
        <Route path="settingroles" element={<SettingRoles />} />
        <Route path="settingcompanies" element={<SettingCompanies />} />
        <Route path="settingauthority" element={<SettingAuthority />} />
        <Route
          path="authoritiesbyrole/:roleId"
          element={<SettingAuthorityByRoleManagement />}
        />
      </Route>
      <Route path="/notfound" element={<NotFound />} />
      {/* <Route path="*" element={<Navigate to="/notfound" replace />} /> */}
    </Route>
  )
);
