import { useState, useEffect } from "react";
import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  TextField,
  Switch,
  styled,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  formSchemaOptionsName,
  formSchemaOptionsNameCompany,
} from "../../types/Utils";
import { useApp } from "../../hooks/useApp";

import { getRequestSubcomapny } from "../../services/subcompany/SubcompanyService";

import { getCompanyRelRequest } from "../../services/company/CompanyRelationService";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
  display: "flex",
  justifyContent: "space-between",
  paddingRight: "20px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateUser {
  id?: number;
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  status: string;
  companyID: {
    id?: number;
    name?: string;
  }[];
  roleID: {
    id?: number;
    name?: string;
  }[];
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  companyData?: any;
  roleData?: any;
  handleActiveUser?: any;
}

interface ItemType {
  id: number;
  companyId: number;
  subcompanyId: number;
  description: string;
  roleId: number;
  roleObject?: any;
  active: number;
  error: boolean;
  errorMsg: string;
}

interface ItemTypeSendSubcompanies {
  id: number;
  userId: number;
  companyId: number;
  subcompanyId: number;
  roleId: number;
  action: string;
}

const AddOrUpdateUserModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
  companyData,
  handleActiveUser,
}: DialogProps) => {
  const { authInfo } = useApp();
  //console.log("AddOrUpdateUserModal data=",data);

  //const [items, setItems] = useState<ItemType[]>(data.addSubCompanies);
  const [companyDataAssigned, setCompanyDataAssigned] = useState<ItemType[]>(
    []
  );
  const [roleDataSubCompanies, setRoleDataSubCompanies] = useState<ItemType[]>(
    []
  );
  const [itemSubCompanyRoles, setItemSubCompanyRoles] = useState<ItemType[]>(
    []
  );
  const [itemCompaniesAssigned, setitemCompaniesAssigned] = useState<
    ItemType[]
  >([]);
  const [companyIDAssigned, setCompanyIDAssigned] = useState(0);
  const [userID, setUserID] = useState(0);
  const [roleUserID, setRoleUSerID] = useState(0);

  const [erroCompany, setErroCompany] = useState({
    active: false,
    message: "",
  });

  const adminView = authInfo.userRole.authorityRoles.some(
    (authority: any) => authority.authority.code === "ADMIN:VIEWDASHBOARD"
  );

  const userView = authInfo.userRole.authorityRoles.some(
    (authority: any) => authority.authority.code === "USER:VIEWDASHBOARD"
  );

  useEffect(() => {
    handleFetchData();
  }, []);

  //Validación de datos
  const validation = Yup.object().shape({
    username: Yup.string()
      .required("Campo es requerido")
      .email("Email es invalido"),
    firstName: Yup.string().required("Campo es requerido"),
    lastName: Yup.string().required("Campo es requerido"),
    password: Yup.string().when(modalType, {
      is: () => {
        if (modalType === "create") return true;
      },
      then: Yup.string().required("Campo es requerido"),
    }),
    phone: Yup.string().required("Campo es requerido"),
    status: Yup.string(),
    /* */
    roleID: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),

    /*
    companyID: Yup.object()
      .shape(formSchemaOptionsNameCompany)
      .typeError("Campo es requerido"),
      */
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    username: data.username ? data.username : "",
    firstName: data.firstName ? data.firstName : "",
    lastName: data.lastName ? data.lastName : "",
    password: data.password ? data.password : "",
    phone: data.phone ? data.phone : "",
    status: data.status ? data.status : "",
    /* roleID: data.roleID
      ? Role.userRole.find((f: any) => f.role_name === data.roleID)
      : "", */
    /*
    companyID: data.userRole
      ? roleData.find((f: any) => f.description === data.userRole)
      : [],  
      */
    roleID: data.userRole
      ? roleData.find((f: any) => f.name === data.userRole)
      : [],

    activationCode: data.activationCode ? data.activationCode : "",
  };
  const handleFetchData = async () => {
    try {
      //console.log("handleFetchData data=",data);
      //console.log("handleFetchData roleData=",roleData);
      setUserID(data.id);

      //@ts-ignore
      const roleDataNew = roleData.filter(
        (objeto: any) => objeto.name !== "Administrador"
      );
      setRoleDataSubCompanies(roleDataNew);

      //buscar datos de compania
      if (data.id !== 0) {
        let companyrelationData: any = await getCompanyRelRequest(0, data.id);
        //console.log("companyData=", companyData);
        //console.log("companyrelationData=", companyrelationData.content);

        if (companyrelationData.content.length != 0) {
          if (data.userRole === "Administrador") {
            //console.log("Es administrador: ", companyData);
            setCompanyDataAssigned(companyData);
          } else {
            //@ts-ignore
            const companyDataNew = companyData.filter(
              (objeto: any) => objeto.description !== "Mapprisk"
            );
            //console.log("companyDataNew=", companyDataNew);
            setCompanyDataAssigned(companyDataNew);
          }
          setitemCompaniesAssigned(companyrelationData.content);
          const companySel = companyrelationData.content[0].companyId;
          //console.log("companySel=", companySel);
          //@ts-ignore
          const foundCompany = companyData.find(
            (item: any) => item.id === companySel
          );
          //console.log("foundCompany=", foundCompany);
          //@ts-ignore
          const foundRole = roleData.find(
            (item: any) => item.name === data.userRole
          );
          //console.log("foundRole=", foundRole);

          if (foundCompany) {
            setCompanyIDAssigned(foundCompany);
            resetField("companyID", {
              defaultValue: foundCompany,
            });
            handelCompany(foundCompany, companyrelationData.content);
            if (foundRole) {
              setRoleUSerID(foundRole.id);
            }
          }
        } else {
          //no hay ninguna compania asignada
          //console.log(data.userRole)
          if (data.userRole === "Administrador") {
            //@ts-ignore
            const foundCompany = companyData.find((item) => item.id === 1);
            //console.log("foundCompany=",foundCompany);
            if (foundCompany) {
              setCompanyIDAssigned(foundCompany);
              resetField("companyID", {
                defaultValue: foundCompany,
              });
            }
            setCompanyDataAssigned(companyData);
          } else {
            setCompanyDataAssigned(companyData);
          }
        }
      } else {
        setCompanyDataAssigned(companyData);
      }
    } catch (error: any) {
      console.log("handleFetchData error=", error.message);
    }
  };
  const handelCompany = async (dataCompany: any, dataCompanyRelation: any) => {
    try {
      if (dataCompany === null) {
        setItemSubCompanyRoles([]);
        return;
      }

      let subCompanyData: any = await getRequestSubcomapny(0, dataCompany.id);
      //console.log("subCompanyData=", subCompanyData.content);

      if (subCompanyData.content.length === 0) {
        setItemSubCompanyRoles([]);
      } else {
        let dataSubCompanyRoles: ItemType[] = [];
        let dataCompanyRelationResult: ItemType[] = dataCompanyRelation;
        let roleDatanResult: ItemType[] = roleData;
        //console.log("handelCompany dataCompanyRelationResult=",dataCompanyRelationResult);
        //console.log("handelCompany roleDatanResult=",roleDatanResult);

        for (let i = 0; i < subCompanyData.content.length; i++) {
          const obj = subCompanyData.content[i];

          let id = 0;
          let roleId = 0;
          let active = 0;
          let roleObject = {};
          if (dataCompanyRelation != null) {
            const foundItem: any = dataCompanyRelationResult.find(
              (item) =>
                item.companyId === obj.companyId.id &&
                item.subcompanyId === obj.id
            );
            //console.log("handelCompany foundItem=",foundItem);
            if (foundItem) {
              id = foundItem.id;
              roleId = foundItem.roleId;
              active = 1;
              const foundItemRol: any = roleDatanResult.find(
                (item) => item.id === roleId
              );
              //console.log("handelCompany foundItemRol=",foundItemRol);
              roleObject = foundItemRol;
            }
          }

          const newItem = {
            id: id,
            companyId: obj.companyId.id,
            subcompanyId: obj.id,
            description: obj.description,
            roleId: roleId,
            roleObject: roleObject,
            active: active,
            error: false,
            errorMsg: "",
          };
          dataSubCompanyRoles.push(newItem);
        }
        //console.log("dataSubCompanyRoles=", dataSubCompanyRoles);
        setItemSubCompanyRoles(dataSubCompanyRoles);
      }
    } catch (error: any) {
      console.log("handelCompany error=", error.message);
    }
  };
  const handleSubCompanyRoleChange = (newValue: any, index: number) => {
    try {
      if (newValue !== null) {
        const updatedItems = [...itemSubCompanyRoles];
        updatedItems[index].roleId = newValue.id;
        updatedItems[index].roleObject = newValue;
        updatedItems[index].error = false;
        updatedItems[index].errorMsg = "";
        // console.log("updatedItemsRole: ", updatedItems);
        setItemSubCompanyRoles(updatedItems);
      }
    } catch (error: any) {
      console.log("handleSubCompanyRoleChange error=", error.message);
    }
  };
  const updateItemActive = (index: number, value: any) => {
    //console.log("updateItemActive value=", value);
    const updatedItems = [...itemSubCompanyRoles];

    updatedItems[index].active = parseInt(value) ? 0 : 1;
    //console.log("Actualiza updatedItems: ", updatedItems);
    setItemSubCompanyRoles(updatedItems);
  };
  const handelRolMain = (dataRol: any) => {
    //console.log("handelRolMain dataRol=",dataRol);
    if (dataRol === null) {
      setRoleUSerID(0);
      setCompanyDataAssigned(companyData);
    } else {
      const idRol = dataRol.id;
      if (dataRol.name === "Administrador") {
        setItemSubCompanyRoles([]);
        //@ts-ignore
        const foundCompany = companyData.find((item) => item.id === 1);
        //console.log("foundCompany=",foundCompany);
        if (foundCompany) {
          setCompanyIDAssigned(foundCompany);
          resetField("companyID", {
            defaultValue: foundCompany,
          });
        }
      } else {
        /*
        setCompanyIDAssigned(0);
        resetField("companyID",{
          //@ts-ignore
          defaultValue:null
        })
        //@ts-ignore
        const companyDataNew = companyData.filter(objeto => objeto.description !== "Mapprisk");
        //console.log("companyDataNew=",companyDataNew)
        setCompanyDataAssigned(companyDataNew);
        */
      }
      setRoleUSerID(idRol);
    }
  };

  const onSubmit2 = (data: any) => {
    try {
      let bActive = true;
      /*  console.log("onSubmit2 data=", data);
      console.log("onSubmit2 itemCompaniesAssigned=", itemCompaniesAssigned);
      console.log("onSubmit2 itemSubCompanyRoles=", itemSubCompanyRoles);
      console.log("onSubmit2 companyIDAssigned=", companyIDAssigned); */
      //console.log("onSubmit2 dataCompanyRelation=",dataCompanyRelation);

      if (companyIDAssigned === null) {
        setErroCompany({ active: true, message: "Campo es requerido" });
        return;
      }
      /**/
      if (itemSubCompanyRoles.length !== 0) {
        let hasSubCompany = false;
        for (let i = 0; i < itemSubCompanyRoles.length; i++) {
          const obj = itemSubCompanyRoles[i];
          if (obj.active === 1) {
            hasSubCompany = true;
          }
        }
        if (!hasSubCompany) {
          setErroCompany({
            active: true,
            message: "Debes seleccionar al menos una sub compañía",
          });
          return;
        }
      }

      setErroCompany({ active: false, message: "" });
      data.companyID = companyIDAssigned;
      //console.log("onSubmit2 data=", data);

      //console.log("data.companyID.id=", data.companyID.id);
      //agregar la compania main
      if (itemCompaniesAssigned.length === 0) {
        //crear no existe
        const newItem = {
          id: 0,
          userId: parseInt(data.id),
          companyId: parseInt(data.companyID.id),
          subcompanyId: null,
          roleId: parseInt(data.roleID.id),
          action: "create",
        };
        data.objCompanyMain = newItem;
      } else {
        //ya tiene uno asignado poder editar
        //@ts-ignore
        const foundItem: any = itemCompaniesAssigned.find(
          (item) => item.subcompanyId === null
        );
        //console.log("foundItem=", foundItem);
        const action =
          data.companyID.id === foundItem.companyId ? "update" : "create";

        const updateitem = {
          id: foundItem.id,
          userId: parseInt(data.id),
          companyId: parseInt(data.companyID.id),
          subcompanyId: null,
          roleId: parseInt(data.roleID.id),
          action: action,
        };
        data.objCompanyMain = updateitem;
      }

      //agregar la sub companias
      let bValidateSubCompanies = false;

      if (data.roleID.name === "Administrador") {
        //if (parseInt(data.roleID.id) === 2 || parseInt(data.roleID.id) === 1) {
        //Si user es admin borra el objSubCompany
        //console.log("Borra el objSubCompany ");
        data.objSubCompany = [];
      } else {
        if (itemSubCompanyRoles.length === 0) {
          //no existen subcompanias
          data.objSubCompany = [];
        } else {
          let dataMultiple: ItemTypeSendSubcompanies[] = [];
          let action = "";
          let objItem = {};
          /* console.log(
            "itemSubCompanyRoles dentro del submit: ",
            itemSubCompanyRoles
          ); */
          for (let i = 0; i < itemSubCompanyRoles.length; i++) {
            const obj = itemSubCompanyRoles[i];
            bValidateSubCompanies = true;
            const foundItem: any = itemCompaniesAssigned.find(
              (item) =>
                item.companyId === obj.companyId &&
                item.subcompanyId === obj.subcompanyId
            );
            //console.log("foundItem dentro del submit: sub", foundItem);
            if (foundItem) {
              //editar
              action = obj.active === 1 ? "update" : "delete";
              objItem = {
                id: obj.id,
                userId: parseInt(data.id),
                companyId: parseInt(data.companyID.id),
                subcompanyId: obj.subcompanyId,
                roleId: obj.roleId,
                action: action,
              };

              //valida que tenga un rol
              if (action === "update" && obj.roleId === 0) {
                //console.log("Agrega error en update");
                //@ts-ignore
                itemSubCompanyRoles[i].error = 1;
                itemSubCompanyRoles[i].errorMsg = "Campo es requerido";
                bActive = false;
              } else {
                //@ts-ignore
                itemSubCompanyRoles[i].error = 0;
                itemSubCompanyRoles[i].errorMsg = "";
              }
            } else {
              //agregar
              //console.log("obj-active: ", obj.active);
              action = obj.active === 1 ? "create" : "deleteOmitir";
              objItem = {
                id: 0,
                userId: parseInt(data.id),
                companyId: parseInt(data.companyID.id),
                subcompanyId: obj.subcompanyId,
                roleId: obj.roleId,
                action: action,
              };

              //valida que tenga un rol
              if (action === "create" && obj.roleId === 0) {
                //console.log("Agrega error en crear");
                //@ts-ignore
                itemSubCompanyRoles[i].error = 1;
                itemSubCompanyRoles[i].errorMsg = "Campo es requerido";
                bActive = false;
              } else {
                // console.log("Quita error en deleteOmitir");
                //@ts-ignore
                itemSubCompanyRoles[i].error = 0;
                itemSubCompanyRoles[i].errorMsg = "";
              }
            }
            //console.log("action=", action);
            //@ts-ignore
            dataMultiple.push(objItem);
          }
          data.objSubCompany = dataMultiple;
        }
      }
      //eliminar una compania y sus subcompanias anteriores
      /**/
      if (itemCompaniesAssigned.length != 0) {
        //console.log("itemCompaniesAssigned: ", itemCompaniesAssigned);

        let objItem = {};
        for (let i = 0; i < itemCompaniesAssigned.length; i++) {
          const obj = itemCompaniesAssigned[i];
          //console.log("OBJ de eliminar", obj);
          //@ts-ignore
          if (parseInt(data.companyID.id) != parseInt(obj.companyId)) {
            objItem = {
              id: obj.id,
              userId: parseInt(data.id),
              companyId: obj.companyId,
              subCompanyId: obj.subcompanyId,
              roleId: obj.roleId,
              action: "delete",
            };
            //console.log(objItem);
            data.objSubCompany.push(objItem);
          }
        }
      }

      //console.log("resultado final data=", data);
      //if (!bValidateSubCompanies) bActive=false;

      //bActive = false; //debug
      if (bActive) onSubmit(data);
    } catch (error: any) {
      console.log("onSubmit2 error=", error.message);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    resetField,
  } = useForm<AddOrUpdateUser>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "update"
          ? properties.com_parval_label_user_update_title
          : properties.com_parval_label_user_add_title}
        {data.status === "DELETED" && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleActiveUser}
          >
            Activar usuario
          </Button>
        )}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={6}>
              <Controller
                name={"firstName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="firstName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("firstName")}
                    error={errors.firstName && Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name={"lastName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Apellido"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="lastName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("lastName")}
                    error={errors.lastName && Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name={"username"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Correo electrónico"
                    type="mail"
                    variant="standard"
                    value={value}
                    autoComplete="username3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("username")}
                    error={errors.username && Boolean(errors.username)}
                    helperText={errors.username && errors.username.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name={"phone"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Teléfono"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="phone"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("phone")}
                    error={errors.phone && Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                  />
                )}
              />
            </Grid>

            {modalType === "create" && (
              <Grid item xs={12}>
                <Controller
                  name={"password"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Contraseña"
                      type="password"
                      variant="standard"
                      value={value}
                      autoComplete="password"
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Controller
                control={control}
                name="roleID"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                      handelRolMain(newValue);
                    }}
                    options={roleData}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Perfil"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.roleID}
                          helperText={errors.roleID && errors.roleID.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            {roleUserID !== 1 && roleUserID !== 0 ? (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="companyID"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                        setCompanyIDAssigned(newValue);
                        handelCompany(newValue, null);
                      }}
                      options={companyDataAssigned}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      //@ts-ignore
                      value={companyIDAssigned}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Compañía"
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            //error={!!errors.companyID}
                            //helperText={errors.companyID && errors.companyID.message}
                            error={erroCompany.active}
                            helperText={erroCompany.message}
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            ) : null}
          </Grid>

          {/* sub companias*/}
          {roleUserID === 2 || roleUserID === 6 ? (
            <>
              {itemSubCompanyRoles &&
                itemSubCompanyRoles.map((row: any, index: number) => (
                  <Grid
                    key={index}
                    container
                    maxWidth="lg"
                    sx={{ mt: 0, mb: 2, pt: 2, pb: 2 }}
                    style={{ borderRadius: "10px", backgroundColor: "#eee" }}
                  >
                    <Grid item xs={12} md={2} style={{ textAlign: "center" }}>
                      <Typography>Asignar</Typography>
                      <Switch
                        checked={row.active}
                        value={row.active}
                        onChange={(event) =>
                          updateItemActive(index, event.target.value)
                        }
                        color="primary"
                      />
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ pr: 5 }}>
                      <TextField
                        fullWidth
                        label="Sub compañía"
                        value={row.description}
                        type="text"
                        variant="standard"
                        sx={{ "& input": { pl: "0!important" } }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ pr: 5 }}>
                      <Autocomplete
                        onChange={(event, newValue) =>
                          handleSubCompanyRoleChange(newValue, index)
                        }
                        options={roleDataSubCompanies}
                        getOptionLabel={(option: any) => option.name || ""}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={row.roleObject}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={`Perfil Subcompañía`}
                            variant="standard"
                            sx={{ "& input": { pl: "0!important" } }}
                            error={row.error}
                            helperText={row.errorMsg}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                ))}
              <Grid
                container
                maxWidth="lg"
                style={{ backgroundColor: "transparent", height: "30px" }}
              ></Grid>
            </>
          ) : null}
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit2)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateUserModal;
