import { URL } from "../utils/UrlConstants";
import { privateFetch } from "../utils/CustomFetch.js";
import { CONSTANT } from "../utils/Constants";
import { formatDate } from "../utils/UtilsFunctions";
import { MoreInquiries } from "../types/Utils";



export const getRequestCompaniesMonth = async (date:any, companyId?:any, subcompanyId?:any, username?:any ) => {
  let responseData = {};
  /* const params = new URLSearchParams([    
    ["companyId", filter.companyId ? filter.companyId : ""],
    ["subcompanyId", filter.subcompanyId ? filter.subcompanyId : ""],
    ["startDate", formatDate(filter.startDate)],
    ["endDate", formatDate(filter.endDate)],
  ]);  */
  
 
  await privateFetch 
    //.get(URL.REQUEST_COMPANY_DASHBOARD, { params })    
    .get(URL.REQUEST_COMPANY_DASHBOARD(date.startDate, date.endDate, companyId && companyId, subcompanyId && subcompanyId, username && username))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestAllUserAdm = async ( ) => {
  let responseData = {};    
  await privateFetch 
    .get(URL.REQUEST_USERS_DASHBOARD)    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestAllConsultingByCompany = async (companyId?:string, subcompanyId?:string) => {
  let responseData = {};    
  await privateFetch 
    .get(URL.REQUEST_CONSULTING_BY_COMPANY_DASHBOARD(companyId, subcompanyId))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestAllUserConsulting = async (companyId:string, subcompanyId?:string) => {
  let responseData = {};    
  await privateFetch 
    .get(URL.REQUEST_USERS_BY_COMPANY_DASHBOARD(companyId, subcompanyId))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestTopCompanies = async () => {
  let responseData = {};    
  await privateFetch 
    .get(URL.REQUEST_TOP_COMPANIES)    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestAllCompanies = async () => {
  let responseData = {};    
  await privateFetch 
    .get(URL.REQUEST_ALL_COMPANIES_DASHBOARD)    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestComparationByMonthCompanies = async (id:string) => {
  let responseData = {};    
  await privateFetch 
    .get(URL.REQUEST_COMPANY_COMPARATION_DASHBOARD(id))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestTopUsers = async (companyId:string, subcompanyId?:string) => {
  let responseData = {};    
  await privateFetch 
    .get(URL.REQUEST_TOP_USERS(companyId, subcompanyId))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};

export const getRequestInquiries = async (companyId:string, subcompanyId?:string) => {
  let responseData = {};    
  await privateFetch 
    .get(URL.REQUEST_NUMBER_INQUIRIES(companyId, subcompanyId))    
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);     
    });
  return responseData;
};


export const requestMoreInquiries = async (adminID:string, data:any) => {
  let responseData = {}  
  await privateFetch
    .post(URL.REQUEST_MORE_INQUIRIES(adminID), data)
    .then(async (response) => {
      responseData = await response.data
    })
    .catch((error) => {
      throw new Error(error.message)
    })
  return responseData
};












