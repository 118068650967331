import {
  Button,
  Card,
  CardContent,
  CardHeader,
  SvgIcon,
  useTheme,
  alpha,
} from "@mui/material";
import { Chart } from "../components/Chart";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const useChartOptions = () => {
  const theme = useTheme();
  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: [
      alpha(theme.palette.primary.main, 0.25),
      theme.palette.primary.main,
    ],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "40px",
      },
    },
    stroke: {
      colors: ["transparent"],
      show: true,
      width: 2,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.primary,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value: any) => (value > 0 ? `${value}` : `${value}`),
        offsetX: -10,
        style: {
          colors: theme.palette.text.primary,
        },
      },
    },
  };
};

const ComparativeGraph = (props: any) => {
  const { chartSeries, sx, title, handleClick } = props;
  const chartOptions = useChartOptions();

  return (
    <Card sx={sx}>
      <CardHeader
        action={
          <Button
            color="inherit"
            size="small"
            name="comparationByMonthCompanies"
            startIcon={
              <SvgIcon fontSize="small">
                <AutorenewIcon />
              </SvgIcon>
            }
            onClick={handleClick}
          >
            Sync
          </Button>
        }
        title={title}
      />
      <CardContent>
        <Chart
          height={350}
          options={chartOptions}
          //data={chartSeries}
          series={chartSeries}
          type="bar"
          width="100%"
        />
      </CardContent>
    </Card>
  );
};

export default ComparativeGraph;
