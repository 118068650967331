import { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { loginTypes } from "../../types/Login";
import {
  SVloginCodeRequest,
  SVloginRequest,
} from "../../services/security/SecurityService";
import { properties } from "../../utils/Properties_es";
import { setAuthData, getItem } from "../../utils/LocalStorageManager";
import PaperSessions from "../../components/PaperSessions";
import { findByIdRequest } from "../../services/company/CompanyService";
import { getRequestSubcomapny } from "../../services/subcompany/SubcompanyService";
import { RequestCompanyRelation } from "../../services/relationcompany/RelationCompanyService";

const Login: FC = () => {
  const navigate = useNavigate();
  const {
    setLoading,
    setErrorMsg,
    setSuccessMsg,
    loginProvider,
    haveCompany,
    subCompanyData,
    authInfo,
  } = useApp();
  const [showCode, setShowCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { redirect } = useParams();

  //console.log(redirect, "redirect en Login.tsx");

  const validation = Yup.object().shape({
    username: Yup.string()
      .required("Email es requerido")
      .email("Email es invalido"),
    password: Yup.string().required("Contraseña es requerida"),
    code: Yup.string(),
    subCompanyId: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<loginTypes>({
    resolver: yupResolver(validation),
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data: any) => {
    //console.log(data);
    try {
      setLoading && setLoading(true);
      //Get two factor code
      if (!showCode) {
        let request: any = await SVloginCodeRequest(data);
        setLoading && setLoading(false);
        setShowCode(true);
        setSuccessMsg && setSuccessMsg(request?.message);
        //console.log(data.userPortal);
        if (request) {
          //console.log(request.companyInfo);
          //console.log(request.companyInfo[0]);

          await setAuthData("companyDataAdmin", {
            companyId: Number(request.companyInfo[0].id),
            companyName: request.companyInfo[0].name ?? "",
          });
        }

        return;
      }

      if (subCompanyData !== null) {
        if (data.subCompanyId === "") {
          setErrorMsg && setErrorMsg("Debe seleccionar una sub compañía");
          setLoading && setLoading(false);
          return;
        }

        const filterSubCompany = await subCompanyData.filter(
          (s: any) => Number(s.id) === Number(data.subCompanyId)
        );
        //console.log(filterSubCompany, "filterSubCompany en Login");

        let dataCompany: any = await getItem("companyDataAdmin");
        let dataSubcompanies: any = await getRequestSubcomapny(
          0,
          Number(filterSubCompany[0].companyId.id)
        );
        //console.log("dataCompany login: ", dataCompany);
        const getDataCompany: any = await findByIdRequest(
          Number(dataCompany.companyId)
        );

        //Load subCompanyRelation
        const companyRelation: any = await RequestCompanyRelation(0, 50, {
          companyId: dataCompany.companyId,
        });
        //console.log("companyRelation: ", companyRelation);

        //console.log("dataSubcompanies en Login: ", dataSubcompanies);
        let filterSubCompanyData = dataSubcompanies.content.filter(
          (item: any) => item.id === Number(filterSubCompany[0]?.id)
        );

        await setAuthData("companyDataAdmin", {
          ...dataCompany,
          companyAllData: getDataCompany?.content[0],
          subCompanyAllData: filterSubCompanyData[0] ?? null,
        });
        await setAuthData("companyData", {
          companyId: Number(filterSubCompany[0].companyId.id),
          companyName: filterSubCompany[0]?.companyId?.description ?? "",
          subcompanyId: Number(filterSubCompany[0]?.id),
          subCompanyName: filterSubCompany[0]?.description ?? "",
          numberInquiries: Number(filterSubCompany[0]?.numberInquiries) ?? "",
          massiveRequest:
            filterSubCompany[0]?.massiveRequest === null
              ? false
              : filterSubCompany[0]?.massiveRequest,
          userRole: companyRelation?.content?.filter(
            (item: any) =>
              item.subcompanyId === Number(filterSubCompany[0]?.id) &&
              item.userId === authInfo.id
          )[0],
        });
        navigate(
          redirect
            ? `/dashboard/batchlist/details/${redirect}`
            : "/dashboard/home"
        );
        setLoading && setLoading(false);
        return;
      }
      //Authenticate user
      const loginData: any = await SVloginRequest(data);
      await loginProvider(loginData);
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg &&
        setErrorMsg(
          error.message
            ? error.message
            : properties.com_parval_label_login_general_error
        );
    }
  };

  return (
    <PaperSessions
      to="rememberpassword"
      textLink="¿Olvidaste tu contraseña?"
      titleForm="Iniciar sesión"
    >
      <form>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name={"username"}
              control={control}
              render={() => (
                <TextField
                  fullWidth
                  size="small"
                  label="Correo electrónico"
                  type="email"
                  variant="standard"
                  autoComplete="username"
                  disabled={showCode}
                  {...register("username")}
                  error={errors.username && Boolean(errors.username)}
                  helperText={errors.username && errors.username.message}
                  sx={{
                    "& .MuiInput-root:before": {
                      borderBottomColor: "#8bc8bc!important",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name={"password"}
              control={control}
              render={() => (
                <TextField
                  fullWidth
                  size="small"
                  label="Contraseña"
                  type={showPassword ? "text" : "password"}
                  variant="standard"
                  disabled={showCode}
                  {...register("password")}
                  error={errors.password && Boolean(errors.password)}
                  helperText={errors.password && errors.password.message}
                  sx={{
                    "& .MuiInput-root:before": {
                      borderBottomColor: "#8bc8bc!important",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} hidden={!showCode}>
            <Controller
              name={"code"}
              control={control}
              render={() => (
                <TextField
                  fullWidth
                  label="Código de validación"
                  size="small"
                  type="text"
                  variant="standard"
                  disabled={haveCompany}
                  {...register("code")}
                  error={!!errors.code}
                  helperText={!!errors.code && errors.code?.message}
                  sx={{
                    "& .MuiInput-root:before": {
                      borderBottomColor: "#8bc8bc!important",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} hidden={subCompanyData === null}>
            <Controller
              name={"code"}
              control={control}
              render={() => (
                <FormControl fullWidth>
                  <InputLabel sx={{ left: "-14px" }}>
                    Seleccione sub compañia
                  </InputLabel>
                  <Select variant="standard" {...register("subCompanyId")}>
                    {subCompanyData &&
                      subCompanyData?.length > 1 &&
                      subCompanyData?.map((s: any) => (
                        <MenuItem value={s.id} key={s.id}>
                          {s.description}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              data-name="update"
              name="update"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              sx={{
                borderRadius: "0.25rem",
                mt: 3,
              }}
            >
              {subCompanyData !== null ? "Continuar" : "Iniciar sesión"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </PaperSessions>
  );
};

export default Login;
