import Typography from "@mui/material/Typography";

const TitleForm = ({ children, ...props }: any) => {
  return (
    <Typography
      align="center"
      variant="h5"
      sx={{ mb: 4, fontSize: "1.5rem" }}
      color="primary"
      {...props}
    >
      {children}
    </Typography>
  );
};

export default TitleForm;
