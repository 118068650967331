import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  Typography,
  Avatar,
  Card,
  CardContent,
  Link,
} from "@mui/material";
import AvatarUser from "../../resources/images/avatar.jpg";
import { LexisNexisData } from "../../types/LexisSearch";
import { Fragment } from "react";
import {
  capitalize,
  formatDateSearch,
  formatPropertyName,
} from "../../utils/UtilsFunctions";

const Title = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "1rem",
  borderBottom: `1px solid #dee2e6`,
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
  mt: 4,
}));

interface QueriesType {
  queries?: number;
}

const ValidateClientDetalis = ({
  searchName,
  cancelModal,
  onSubmit,
  data,
  allInput,
  downloadPdfIndividual,
}: {
  searchName: string;
  cancelModal: () => void;
  onSubmit: (data: QueriesType) => void;
  data: LexisNexisData;
  allInput: any;
  downloadPdfIndividual: any;
}) => {
  const options: any = [];
  for (let i = 50; i <= 1000; i += 50) {
    options.push({
      value: i,
      label: i,
    });
  }

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        Detalle de búsqueda
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <Grid container spacing={8} sx={{ pt: 4, pb: 4 }}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography
              sx={{ fontSize: 14 }}
              color="#43a491"
              style={{ fontWeight: "bold" }}
            >
              Profile Data
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} lg={2} style={{ display: "flex" }}>
            <Avatar
              alt="User"
              src={AvatarUser}
              style={{
                width: "100px",
                height: "100px",
                marginLeft: "auto",
                marginRight: "auto",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <Grid container>
              {/* items */}
              <Grid item xs={12} md={6} lg={6}>
                {allInput.typeperson === "Física" ? (
                  <Card variant="outlined" style={{ margin: "8px" }}>
                    <CardContent style={{ padding: "8px" }}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#43a491"
                        style={{ fontWeight: "bold" }}
                      >
                        Datos de entrada
                      </Typography>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        Tipo de entidad:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {data?.entityDetails?.entityType === "INDIVIDUAL"
                          ? "Persona fisica"
                          : "Organización"}
                      </Typography>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        Nombre:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {/* {data.entityName} */}
                        {allInput.name} {allInput.name2} {allInput.lastname}{" "}
                        {allInput.lastname2}
                        {/* Probando */}
                      </Typography>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        Dirección:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {allInput.address1 || "N/A"}
                      </Typography>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        Pais:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {allInput.country.name || "N/A"}
                      </Typography>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        Número de identificacion:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {allInput.document_id_number || "N/A"}
                      </Typography>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        Ciudad:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {/* &nbsp; */}
                        {allInput.city || "N/A"}
                      </Typography>
                    </CardContent>
                  </Card>
                ) : (
                  <Card variant="outlined" style={{ margin: "8px" }}>
                    <CardContent style={{ padding: "8px" }}>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="#43a491"
                        style={{ fontWeight: "bold" }}
                      >
                        Datos de entrada
                      </Typography>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        Tipo de entidad:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {data.entityDetails?.entityType === "INDIVIDUAL"
                          ? "Persona fisica"
                          : "Organización"}
                      </Typography>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        Nombre:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {/* {data.entityName} */}
                        {allInput.juridico_nombre}
                      </Typography>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        Número de identificación:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {allInput.juridico_id || "N/A"}
                      </Typography>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        País:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {/* &nbsp; */}
                        {allInput?.country?.name
                          ? allInput.country.name
                          : "N/A"}
                      </Typography>

                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        Ciudad:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {allInput.city || "N/A"}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                      >
                        Dirección:
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        {allInput.address1 || "N/A"}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Grid>

              {/* items */}
              <Grid item xs={12} md={6} lg={6}>
                <Card variant="outlined" style={{ margin: "8px" }}>
                  <CardContent style={{ padding: "8px" }}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="#43a491"
                      style={{ fontWeight: "bold" }}
                    >
                      Datos de perfil
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                    >
                      Tipo de entidad:
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                      style={{
                        background: "#dff7ef",
                        padding: "2px 5px",
                        borderBottom: "1px solid #43a491",
                        marginBottom: "5px",
                      }}
                    >
                      {data?.entityDetails?.entityType === "INDIVIDUAL"
                        ? "Persona fisica"
                        : "Organización"}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                    >
                      Nombre:
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                      style={{
                        background: "#dff7ef",
                        padding: "2px 5px",
                        borderBottom: "1px solid #43a491",
                        marginBottom: "5px",
                      }}
                    >
                      {data?.entityName}
                    </Typography>
                    {allInput.typeperson2 === "Física" && (
                      <>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                        >
                          Fecha nacimiento:
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                          style={{
                            background: "#eee",
                            padding: "2px 5px",
                            borderBottom: "1px solid #5C5D60",
                            marginBottom: "5px",
                          }}
                        >
                          {!!data.entityDetails?.additionalInfo
                            ?.entityAdditionalInfo.length &&
                          data.entityDetails?.additionalInfo?.entityAdditionalInfo?.filter(
                            (item: any) => item.type === "DOB"
                          ).length ? (
                            data.entityDetails.additionalInfo.entityAdditionalInfo
                              .filter((item: any) => item.type === "DOB")
                              .slice(0, 2)
                              .map((item: any, index: number) => {
                                return (
                                  <span key={index}>
                                    {item.value} <br />
                                  </span>
                                );
                              })
                          ) : (
                            <span>
                              &nbsp; <br />
                            </span>
                          )}
                        </Typography>

                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                        >
                          Sexo:
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                          style={{
                            background: "#eee",
                            padding: "2px 5px",
                            borderBottom: "1px solid #5C5D60",
                            marginBottom: "5px",
                          }}
                        >
                          {data.entityDetails?.gender}
                        </Typography>
                      </>
                    )}

                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                    >
                      Números de identificación:
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                      style={{
                        background: "#eee",
                        padding: "2px 5px",
                        borderBottom: "1px solid #5C5D60",
                        marginBottom: "5px",
                      }}
                    >
                      {!!data.entityDetails?.ids?.entityID.length ? (
                        data.entityDetails?.ids?.entityID.map(
                          (item: any, index: number) => {
                            return (
                              <span key={index}>
                                {item.number} ({formatPropertyName(item.type)}){" "}
                                <br />
                              </span>
                            );
                          }
                        )
                      ) : (
                        <span>
                          &nbsp; <br />
                        </span>
                      )}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                    >
                      {allInput.typeperson2 === "Física"
                        ? "Nacionalidad:"
                        : "País:"}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                      style={{
                        background: "#eee",
                        padding: "2px 5px",
                        borderBottom: "1px solid #5C5D60",
                        marginBottom: "5px",
                      }}
                    >
                      {!!data.entityDetails?.addresses &&
                      !!data.entityDetails?.addresses?.entityAddress ? (
                        data.entityDetails?.addresses?.entityAddress
                          .slice(0, 1)
                          .map((item: any, index: number) => {
                            return (
                              <span key={index}>
                                {item.country} <br />
                              </span>
                            );
                          })
                      ) : (
                        <span>
                          &nbsp; <br />
                        </span>
                      )}
                    </Typography>

                    {allInput.typeperson2 === "Física" && (
                      <>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                        >
                          Lugar de nacimiento:
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                          style={{
                            background: "#eee",
                            padding: "2px 5px",
                            borderBottom: "1px solid #5C5D60",
                            marginBottom: "5px",
                          }}
                        >
                          {!!data.entityDetails?.additionalInfo
                            ?.entityAdditionalInfo?.length &&
                          !!data.entityDetails?.additionalInfo?.entityAdditionalInfo?.filter(
                            (item: any) => item.type === "PLACE_OF_BIRTH"
                          ).length ? (
                            data.entityDetails.additionalInfo.entityAdditionalInfo
                              .filter(
                                (item: any) => item.type === "PLACE_OF_BIRTH"
                              )
                              .map((item: any, index: number) => {
                                return (
                                  <span key={index}>
                                    {item.value} <br />
                                  </span>
                                );
                              })
                          ) : (
                            <span>
                              &nbsp; <br />
                            </span>
                          )}
                        </Typography>

                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                        >
                          Ciudadanía:
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                          style={{
                            background: "#eee",
                            padding: "2px 5px",
                            borderBottom: "1px solid #5C5D60",
                            marginBottom: "5px",
                          }}
                        >
                          {!!data.entityDetails?.additionalInfo
                            ?.entityAdditionalInfo?.length &&
                          !!data.entityDetails?.additionalInfo?.entityAdditionalInfo?.filter(
                            (item: any) => item.type === "CITIZENSHIP"
                          ).length ? (
                            data.entityDetails.additionalInfo.entityAdditionalInfo
                              .filter(
                                (item: any) => item.type === "CITIZENSHIP"
                              )
                              .map((item: any, index: number) => {
                                return (
                                  <span key={index}>
                                    {item.value} <br />
                                  </span>
                                );
                              })
                          ) : (
                            <span>
                              &nbsp; <br />
                            </span>
                          )}
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12}></Grid>

          <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "5px" }}>
            <Typography
              sx={{ fontSize: 14 }}
              color="#43a491"
              style={{ fontWeight: "bold" }}
            >
              Lista de selección
            </Typography>
            <Grid container>
              {/* items */}
              <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined" style={{ margin: "0px" }}>
                  <CardContent style={{ padding: "8px" }}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                    >
                      Nombre de lista de prueba:
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                      style={{
                        background: "#eee",
                        padding: "2px 5px",
                        borderBottom: "1px solid #5C5D60",
                        marginBottom: "5px",
                      }}
                    >
                      {data?.file?.name}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                    >
                      Generación de archivos:
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                      style={{
                        background: "#eee",
                        padding: "2px 5px",
                        borderBottom: "1px solid #5C5D60",
                        marginBottom: "5px",
                      }}
                    >
                      {data?.file?.build && formatDateSearch(data?.file?.build)}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                    >
                      Entidad actualizada por última vez:
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                      style={{
                        background: "#eee",
                        padding: "2px 5px",
                        borderBottom: "1px solid #5C5D60",
                        marginBottom: "5px",
                      }}
                    >
                      {data?.dateModified &&
                        formatDateSearch(data.dateModified)}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                    >
                      Cargo:
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                      style={{
                        background: "#eee",
                        padding: "2px 5px",
                        borderBottom: "1px solid #5C5D60",
                        marginBottom: "5px",
                      }}
                    >
                      {data.entityDetails?.additionalInfo?.entityAdditionalInfo
                        .filter((item: any) => item.type === "POSITION")
                        .map((item: any, index: number) => {
                          return (
                            <span key={index}>
                              {item.value} <br />
                            </span>
                          );
                        })}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                    >
                      Estatus de la entidad:
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.primary"
                      component="div"
                      style={{
                        background: "#eee",
                        padding: "2px 5px",
                        borderBottom: "1px solid #5C5D60",
                        marginBottom: "5px",
                      }}
                    >
                      {data?.status && capitalize(data.status)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          {!!data.enforcements && (
            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "5px" }}>
              <Typography
                sx={{ fontSize: 14 }}
                color="#43a491"
                style={{ fontWeight: "bold" }}
              >
                Segmentos
              </Typography>
              {data.enforcements?.enforcement?.map((item, index: number) => (
                <Grid container marginBottom="1rem" key={index}>
                  {/* items */}
                  <Grid item xs={12} md={12} lg={12}>
                    <Card variant="outlined" style={{ margin: "0px" }}>
                      <CardContent style={{ padding: "8px" }}>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="#43a491"
                          style={{ fontWeight: "bold" }}
                        >
                          Exigibilidad
                        </Typography>

                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                        >
                          País:
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                          style={{
                            background: "#eee",
                            padding: "2px 5px",
                            borderBottom: "1px solid #5C5D60",
                            marginBottom: "5px",
                          }}
                        >
                          {item.country || "N/A"}
                        </Typography>

                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                        >
                          Subcategoría:
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                          style={{
                            background: "#eee",
                            padding: "2px 5px",
                            borderBottom: "1px solid #5C5D60",
                            marginBottom: "5px",
                          }}
                        >
                          {item.subCategories?.string.map(
                            (item: any, index: number) => {
                              return (
                                <span key={index}>
                                  {item} <br />
                                </span>
                              );
                            }
                          )}
                        </Typography>

                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                        >
                          Fuente:
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14 }}
                          color="text.primary"
                          component="div"
                          style={{
                            background: "#eee",
                            padding: "2px 5px",
                            borderBottom: "1px solid #5C5D60",
                            marginBottom: "5px",
                          }}
                        >
                          {item.source}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}

          <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "5px" }}>
            <Typography
              sx={{ fontSize: 14 }}
              color="#43a491"
              style={{ fontWeight: "bold" }}
            >
              Comentarios
            </Typography>
            <Grid container>
              {/* items */}
              <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined" style={{ margin: "0px" }}>
                  <CardContent style={{ padding: "8px" }}>
                    <Typography
                      sx={{ fontSize: 14, whiteSpace: "pre-wrap" }}
                      color="text.primary"
                      component="div"
                      style={{
                        background: "#eee",
                        padding: "2px 5px",
                        borderBottom: "1px solid #5C5D60",
                        marginBottom: "5px",
                      }}
                    >
                      {data.entityDetails?.comments
                        ?.split("\n")
                        .map((line, index) => (
                          <Fragment key={index}>
                            {line}
                            <br />
                          </Fragment>
                        ))}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12} style={{ paddingTop: "5px" }}>
            <Typography
              sx={{ fontSize: 14 }}
              color="#43a491"
              style={{ fontWeight: "bold" }}
            >
              Fuertes de registro ({data.sourceItems?.sourceItem?.length})
            </Typography>
            <Grid container>
              {/* items */}
              <Grid item xs={12} md={12} lg={12}>
                <Card variant="outlined" style={{ margin: "0px" }}>
                  <CardContent style={{ padding: "8px" }}>
                    {data?.sourceItems?.sourceItem?.map((item, index) => (
                      <Typography
                        sx={{ fontSize: 14 }}
                        color="text.primary"
                        component="div"
                        key={index}
                        style={{
                          background: "#eee",
                          padding: "2px 5px",
                          borderBottom: "1px solid #5C5D60",
                          marginBottom: "5px",
                        }}
                      >
                        <Link
                          href={item.sourceURI}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.sourceURI}
                        </Link>
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={() => {
            downloadPdfIndividual(data);
          }}
        >
          Imprimir
        </Button>{" "}
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default ValidateClientDetalis;
